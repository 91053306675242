import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { NumericFormat } from 'react-number-format';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import API from 'API';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditProductModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.form = new FormHelper({
            fields: {
                name: {
                    label: 'Name',
                    type: 'text',
                    autoFocus: true
                },
                category: {
                    label: 'Category',
                    type: 'single-select',
                    blankText: 'No category',
                    getOptions: () => [
                        { value: 'Utility', text: 'Utility' },
                        { value: 'Cowl', text: 'Cowl' }
                    ]
                },
                description: {
                    label: 'Description',
                    type: 'expanding-text',
                    rows: 2
                },
                isSold: {
                    boxLabel: 'This product is sold',
                    type: 'checkbox'
                },
                price: {
                    label: 'Price',
                    type: 'currency'
                }
            },
            getValue: (fieldName) => this.getFieldValue(fieldName),
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                id: null,
                ...opt
            }, () => {
                this.load();
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async load() {
        const {
            id
        } = this.state;

        // Load
        let product;
        if (id) {
            product = await API.call('product/get/' + id);
        } else {
            product = {
            };
        }

        // Update
        this.setState({
            product,
            isLoading: false
        }, () => {
            // TODO uncomment this when enabling QuickBooks
            //this.tryLoadQuickbooksInfo();
        });
    }

    async tryLoadQuickbooksInfo() {
        if (!this.state.quickbooksItems) {
            const isQuickbooksConnected = await API.call('quickbooks/get-is-connected');
            this.setState({ isQuickbooksConnected });
            if (isQuickbooksConnected) {
                const quickbooksItems = await API.call('quickbooks/list-items');
                this.setState({ quickbooksItems });
            }
        }
    }
    
    getFieldValue(fieldName) {
        return this.state.product[fieldName];
    }

    updateFields(fields) {
        const product = { ...this.state.product };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            product[fieldName] = value;
        }
        this.setState({ product });
    }

    async saveAndClose() {
        this.setState({ isLoading: true });
        await API.call('product/save', this.state.product);
        this.close();
        this.resolve();
    }

    async confirmDelete() {
        const confirm = window.confirm('Are you sure you want to delete this product?');
        if (confirm) {
            this.setState({ isLoading: true });
            await API.call('product/delete/' + this.state.id);
            this.close();
            this.resolve();
        }
    }

    render() {
        const { 
            isOpen,
            isLoading,
            id
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="edit-product-modal" backdrop="static" keyboard={false}>
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    <form onSubmit={e => { this.saveAndClose(); e.preventDefault() }}>
                        {/*
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        */}
                        <Modal.Body>

                            {this.renderInner()}

                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                                Cancel
                            </button>
                            {!!id &&
                                <button type="button" className="btn btn-secondary" onClick={() => this.confirmDelete()}>
                                    Delete
                                </button>
                            }
                            <button type="submit" className="btn btn-primary ms-auto">
                                Save Changes
                            </button>
                        </Modal.Footer>
                    </form>
                }
            </Modal>
        );
    }

    renderInner() {
        const { product, isQuickbooksConnected, quickbooksItems } = this.state;

        return (<>

            {this.form.render([
                'name',
                'category',
                'description',
                'isSold',
                product.isSold && 'price'
            ])}

            {isQuickbooksConnected &&
                <div className="form-group mb-0">
                    <label className="form-label">QuickBooks Item/Service</label>
                    {quickbooksItems ?
                        <select
                            className="form-control"
                            value={product.qbItemID || ''}
                            onChange={e => this.updateFields({ qbItemID: e.target.value })}
                        >
                            <option value="">(None)</option>
                            {quickbooksItems.map(qbi =>
                                <option key={qbi.id} value={qbi.id}>
                                    {qbi.name}
                                </option>
                            )}
                        </select> :
                        <Loader />
                    }
                </div>
            }

        </>);
    }
}

export default EditProductModal;