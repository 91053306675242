import React from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

// Services & Helpers
import API from 'API';
import FormHelper from 'helpers/FormHelper';
import TextHelpers from 'helpers/TextHelpers';
import ReferenceHelpers from 'helpers/ReferenceHelpers';
import Print from 'Print';

// Components
import Loader from 'components/common/Loader';
import SuperTable from 'components/common/SuperTable';
import EditPropertyModal from 'components/customer/EditPropertyModal';
import EditProperty from 'components/customer/EditProperty';
import EditAddress from 'components/common/EditAddress';
import DoNotSweepModal from 'components/customer/DoNotSweepModal';
import CustomerCommList from 'components/customer/CustomerCommunication/CustomerCommList';
import ApptList from 'components/customer/ApptList';
import PriceCalcModal from 'components/appt/PriceCalcModal';
import DedupeCustomerModal from 'components/customer/DedupeCustomerModal';
import MovePropertyModal from 'components/customer/MovePropertyModal';
import AddNewRebookModal from 'components/rebook/AddNewRebookModal';
import { Tooltip } from 'react-tooltip';

//-----------------------------------------------------------------

class EditCustomerModal extends React.Component {

    constructor(props) {
        super(props);

        this.editPropertyModalRef = React.createRef();
        this.doNotSweepModalRef = React.createRef();
        this.priceCalcModalRef = React.createRef();
        this.dedupeCustomerModalRef = React.createRef();
        this.movePropertyModalRef = React.createRef();
        this.addNewRebookModalRef = React.createRef();

        this.state = {
            customerRebooks: { rebooks: [] },
            nextRebookDate: null
        };
        
        this.onAddNewRebookSave = this.onAddNewRebookSave.bind(this);
        
        this.changedFields = {};
        this.form = new FormHelper({
            fields: {
                title: {
                    label: 'Title',
                    type: 'text'
                },
                firstName: {
                    label: 'First Name',
                    type: 'text'
                },
                lastName: {
                    label: 'Last Name',
                    type: 'text'
                },
                companyName: {
                    label: 'Company Name',
                    type: 'text'
                },
                landlineTel: {
                    label: 'Phone Number',
                    type: 'tel'
                },
                mobileTel: {
                    label: 'Mobile Number',
                    type: 'tel'
                },
                emailAddress: {
                    label: 'Email Address',
                    type: 'email'
                },
                type: {
                    label: 'Type',
                    type: 'single-select',
                    getOptions: () => ReferenceHelpers.CustomerTypes
                },
                customerSourceID: {
                    label: 'Source',
                    type: 'single-select',
                    blankText: '(Select)',
                    getOptions: () => this.state.customerSources.map(cs => ({
                        value: cs.id,
                        text: cs.name
                    }))
                },
                doNotSweepReason: {

                },
                notes: {
                    label: 'Customer Notes',
                    type: 'expanding-text',
                    rows: 1
                }
            },
            getValue: (fieldName) => this.state.customer[fieldName],
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            
            this.setState({
                isOpen: true,
                isLoading: true,
                id: opt.id,
                propertyID: opt.propertyID,
                cameFrom: opt.cameFrom,
                customerRebooks: { rebooks: []}
            }, () => {
                this.load();
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async loadRebooks(customer) {
        let customerRebooks = await API.call(`rebook/get-for-customer/${customer.id}`)

        if (customerRebooks) {
            this.setState({ customerRebooks: customerRebooks })

            if (customerRebooks.rebooks.length > 0) {
                this.setState({ nextRebookDate: customerRebooks.nextRebookDate });
            }
        } else {
            this.setState({ customerRebooks: { rebooks: []}})
        }
    }
    
    async load(silent) {
        return new Promise(async (resolve, reject) => {
            const { id } = this.state;
            let customer;

            if (id) {
                if (!silent) {
                    this.setState({ isLoading: true });
                }
                customer = await API.call(`customer/get/${id}`);
                if (!customer) {
                    alert('Sorry, the customer could not be found');
                    this.close();
                    return;
                }
                
                await this.loadRebooks(customer);
                
            } else {
                customer = {
                    type: 'OwnerOccupier',
                    billingAddress: {},
                    property: {
                        address: {},
                        chimneys: []
                    }
                };
            }

            // Load related entities
            const customerSources = await API.call('customer-source/list');

            // Update UI
            this.setState({
                isLoading: false,
                isLoadingProperties: true,
                customer,
                customerSources,
            }, async () => {

                this.loadProperties(resolve);

            });
        });
    }

    async deleteRebook(rebookId) {

        let confirmDeletion = window.confirm('Are you sure?')
        
        if (!confirmDeletion) {
            return;
        }

        await API.call(`rebook/delete/${rebookId}`, { method: 'DELETE'});
        await this.load(false);
    }
    
    async openRebook(rebookId) {
        let confirmation = window.confirm("Are you sure?");
        
        if (!confirmation) {
            return;
        }
        
        await API.call(`rebook/open/${rebookId}`);
        await this.load(false);
    }
    
    async closeRebook(rebookId) {
        let confirmation = window.confirm("Are you sure?");
        
        if (!confirmation) {
            return;
        }
        
        await API.call(`rebook/close/${rebookId}`);
        await this.load(false);
    }
    
    async loadProperties(callback) {
        const { customer } = this.state;
        this.setState({ isLoadingProperties: true });
        
        let properties = await this.getCustomerProperties();
        
        if (!ReferenceHelpers.isIndividual(customer.type)) {
            await this.loadLandlordCustomerProperties(properties,  callback);
            return;
        }

        await this.loadIndividualCustomerProperties(properties,  callback);
    }
    
    async loadIndividualCustomerProperties(properties, callback) {
        let property = await this.getIndividualCustomerProperty(properties);

        // Update on customer
        this.updateFields({
            property
        }, () => {
            this.setState({
                isLoadingProperties: false
            }, callback);
        });
    }
    
    async getCustomerProperties() {
        const { customer } = this.state;

        let properties = [];
        
        if (customer.id) {
            properties = await API.call('property/list', { customerID: customer.id });
            return properties;
        }
        
        return properties;
    }
    
    async loadLandlordCustomerProperties(properties, callback) {
        const { propertyID } = this.state;
        
        this.setState({
            properties,
            isLoadingProperties: false
        }, () => {
            if (propertyID) {
                this.editProperty(propertyID);
            }
            if (callback) callback();
        });
    }
    
    async getIndividualCustomerProperty(properties) {
        const { propertyID } = this.state;
        
        let property = {};

        if (propertyID) {
            property = await API.call('property/get/' + propertyID);
            return property;
        }

        if (properties.length > 0 ) {
            property = await API.call('property/get/' + properties[0].id);
            return property;
        }
        
        return property;
    }

    async save() {
        return new Promise(async (resolve) => {
            let { customer } = this.state;
            this.setState({ isLoading: true });
            this.changedFields = {};
            const id = await API.call('customer/save', customer);
            this.setState({ isLoading: false });
            resolve({ action: 'save', id });
        });
    }

    async saveAndReload() {
        const { id } = await this.save();
        this.setState({
            id
        }, () => {
            this.load();
        });
    }

    async saveAndClose() {
        const result = await this.save();
        this.close();
        this.resolve(result);
    }

    updateFields(fields, callback) {
        const customer = { ...this.state.customer };
        let loadProperties;

        for (let fieldName in fields) {
            const oldValue = customer[fieldName];
            const value = fields[fieldName];
            customer[fieldName] = value;
            this.changedFields[fieldName] = true;

            // Load properties if switching between individual and company
            if (fieldName == 'type' && ReferenceHelpers.isIndividual(value) != ReferenceHelpers.isIndividual(oldValue)) {
                loadProperties = true;
            }
        }
        this.setState({
            customer,
            isLoadingProperties: loadProperties
        }, () => {
            if (loadProperties) {
                this.loadProperties(callback);
            } else if (callback) {
                callback();
            }
        });
    }

    updateBillingAddressFields(fields, callback) {
        const billingAddress = { ...this.state.customer.billingAddress };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            billingAddress[fieldName] = value;
        }
        this.updateFields({ billingAddress }, callback);
    }

    updatePropertyFields(fields, callback) {
        const property = { ...this.state.customer.property };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            property[fieldName] = value;
        }
        this.updateFields({ property }, callback);
    }

    async addProperty() {
        const { customer } = this.state;
        let customerID = customer.id;
        if (!customerID) {
            const { id } = await this.save();
            customerID = id;
            this.setState({ id });
        }
        await this.editPropertyModalRef.current.open({
            customerID
        });
        this.load(true);
    }
    
    addNewRebook() {
        this.addNewRebookModalRef.current.open(ReferenceHelpers.isIndividual(this.state.customer.type) ? [this.state.customer.property] : this.state.properties);
    }
    
    async onAddNewRebookSave(changedProperty, selectedYear) {
        if (ReferenceHelpers.isIndividual(this.state.customer.type)) {
            
            let customer = {...this.state.customer};
            customer.property.rebookYear = selectedYear;
            customer.property.rebookMonths = changedProperty.rebookMonths;
            this.setState({customer});

            await API.call('rebook/save-for-property', { propertyID: customer.property.id, customerId: this.state.customer.id, rebookMonths: changedProperty.rebookMonths, rebookYear: changedProperty.rebookYear});
            
            await this.saveAndReload();
            return;
        }        
        
        const customerProperty = this.state.properties.find(p => p.id === changedProperty.id);
        customerProperty.rebookYear = selectedYear;
        customerProperty.rebookMonths = changedProperty.rebookMonths;
        
        await API.call('rebook/save-for-property', { propertyID: customerProperty.id, customerId: this.state.customer.id, rebookMonths: customerProperty.rebookMonths,  rebookYear: customerProperty.rebookYear});
        
        await this.load();
    }

    async editProperty(id) {
        const { customer } = this.state;
        await this.editPropertyModalRef.current.open({
            customer,
            id
        });
        this.loadProperties();
    }

    async showDoNotSweepModal() {
        const { id, doNotSweepReason } = this.state.customer;
        await this.doNotSweepModalRef.current.open({
            customerID: id,
            reason: doNotSweepReason
        });
        this.load();
    }

    async confirmToggleIsArchived() {
        const { id, isArchived } = this.state.customer;
        const confirm = window.confirm((isArchived ? 'Un-archive' : 'Archive') + ' this customer?');
        if (confirm) {
            await API.call('customer/set-is-archived', {
                customerID: id,
                isArchived: !isArchived
            });
            this.updateFields({
                isArchived: !isArchived
            });
            if (!isArchived) {
                this.close();
                this.resolve({ action: 'archive' });
            }
        }
    }

    async confirmDelete() {
        const confirm = window.confirm('Are you sure you want to delete this customer?');
        if (confirm) {
            this.setState({ isLoading: true });
            await API.call('customer/delete/' + this.state.customer.id);
            this.close();
            this.resolve({ action: 'delete' });
        }
    }

    async addAppt() {
        const { id } = await this.save();
        this.close();
        this.props.history.push(`/day-sheet?customerID=${id}`);
    }

    getLatestCertificate(isInline) {
        Print.url('/api/appt/get-certificate-pdf/' + this.state.customer.property.latestApptID, {
            isInline
        });
    }

    confirmResendCompletedNotification() {
        const confirm = window.confirm('Re-send the completion email / certificate?');
        if (confirm) {
            this.resendCompletedNotification();
        }
    }

    async resendCompletedNotification() {
        await API.call('appt/trigger-notification', {
            apptID: this.state.customer.property.latestApptID,
            type: 'ApptCompleted'
        });
        alert('The notification has been sent.');
    }

    showPriceCalcModal() {
        this.priceCalcModalRef.current.open({});
    }

    async switchAccount(accountID) {
        const { loginInfo } = this.props;
        const { id } = await this.save();
        this.setState({ isLoading: true });
        await API.call('customer/switch-account', {
            customerID: id,
            accountID
        });
        const account = loginInfo.accounts.find(a => a.accountID == accountID);
        window.location.href = `/${account.accountCode}/customers/active?customerID=${id}`;
    }

    async showDedupeModal() {
        await this.dedupeCustomerModalRef.current.open({
            customerID: this.state.customer.id
        });
        this.close();
        this.resolve({ action: 'dedupe' });
    }

    async showMovePropertyToOtherCustomerModal() {
        await this.movePropertyModalRef.current.open({
            id: this.state.customer.property.id
        });
        this.load();
    }

    //--------------------------------------------------------------------------

    render() {
        const { 
            isOpen
        } = this.state;

        if (!isOpen) {
            return null;
        }
       
        return (<>
            <Modal show onHide={() => this.close()} className="edit-customer-modal" backdrop="static">
                <form onSubmit={e => { e.stopPropagation(); e.preventDefault(); this.saveAndClose() }}>
                    <Modal.Header>
                        {this.renderControls(true)}
                    </Modal.Header>
                    <Modal.Body>
                        {this.renderInner()}
                    </Modal.Body>
                    <Modal.Footer>
                        {this.renderControls(false)}
                    </Modal.Footer>
                </form>
            </Modal>

            <PriceCalcModal ref={this.priceCalcModalRef} />
            <DedupeCustomerModal ref={this.dedupeCustomerModalRef} />

        </>);
    }
    
    renderRebooksSection() {
        return (
            <>
                <Tooltip id="addRebook" />
                
                <AddNewRebookModal ref={this.addNewRebookModalRef} onAddNewRebookSave={this.onAddNewRebookSave} />

                <h2>
                    Rebooks
                    <i style={{ marginLeft: '10px', marginTop: '3px', cursor: 'pointer', fontSize: '20px' }}
                       className="fa-solid fa-square-plus"
                       data-tooltip-id="addRebook"
                       data-tooltip-content="Add"
                       onClick={() => this.addNewRebook()}></i>

                    {this.state.customerRebooks.nextRebookDate && (
                        <span style={{ fontSize: '14px' }} className="ms-auto">{' '}Next: {moment(this.state.customerRebooks.nextRebookDate).format('MMM YYYY')}</span>
                    )}
                </h2>

                <SuperTable
                    className="table table-bordered"
                    rows={this.state.customerRebooks.rebooks}
                    keyAccessor={rebook => rebook.id}
                    cols={{
                        date: {
                            label: 'Date',
                            getValue: (colInfo, rebook) => {
                                return moment(rebook.date).format('MMMM YYYY');
                            }
                        },
                        lastCommunicationComment: {
                            label: 'Last comment'
                        },
                        isClosed: {
                            label: 'Status',
                            getValue: (colInfo, rebook) => {
                                return rebook.isClosed ? 'Closed' : 'Open';
                            }
                        },
                        action: {
                            label: 'Action',
                            getValue: (colInfo, rebook) => {
                                return (
                                    <div>
                                        <Tooltip id={`openTooltip-${rebook.id}`} />
                                        <Tooltip id={`closeTooltip-${rebook.id}`} />
                                        <Tooltip id={`deleteTooltip-${rebook.id}`} />
                                        <Tooltip id={`addTooltip-${rebook.id}`} />


                                        {rebook.isClosed && (
                                            <i style={{ marginRight: '10px', cursor: 'pointer', fontSize: '20px' }}
                                               className="fa-solid fa-square-arrow-up-right"
                                               onClick={ async () => await this.openRebook(rebook.id)}
                                               data-tooltip-id={`openTooltip-${rebook.id}`}
                                               data-tooltip-content="Open"
                                            ></i>
                                        )}

                                        {!rebook.isClosed && (
                                            <>
                                                <i style={{ marginRight: '10px', cursor: 'pointer', fontSize: '20px'}}
                                                   className="fa-solid fa-square-xmark"
                                                   data-tooltip-id={`closeTooltip-${rebook.id}`}
                                                   data-tooltip-content="Close"
                                                   onClick={async () => await this.closeRebook(rebook.id)}></i>
                                                <i style={{ cursor: 'pointer', fontSize: '20px'}}
                                                   className="fa-solid fa-square-minus"
                                                   data-tooltip-id={`deleteTooltip-${rebook.id}`}
                                                   data-tooltip-content="Delete" 
                                                   onClick={async () => await this.deleteRebook(rebook.id)}></i>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                        }
                    }}
                    emptyText="No rebooks added"
                />
            </>
        )
    }
    
    renderInner() {
        const {
            isLoading,
            isLoadingProperties,
            customer,
            cameFrom
        } = this.state;
        const { history } = this.props;

        if (isLoading) {
            return (<Loader />);
        }

        const isIndividual = ReferenceHelpers.isIndividual(customer.type);

        return (<>

            {!!customer.doNotSweepReason &&
                <section className="warning mb-3">
                    <span className="fa-solid fa-triangle-exclamation" />{' '}
                    DO NOT SWEEP: {customer.doNotSweepReason}
                </section>
            }
            {customer.isArchived &&
                <section className="info mb-3">
                    <span className="fa-solid fa-box-archive" />{' '}
                    This customer is archived
                </section>
            }

            <div className="row">

                <div className="col-md-6">

                    <section>

                        <h2>Customer Details</h2>

                        {this.renderCustomerDetails(isIndividual)}

                    </section>

                    {!!customer.id && customer.property &&
                        <CustomerCommList
                            customerID={customer.id}
                            propertyID={customer.property.id}
                            customerRebooks={this.state.customerRebooks}
                            defaultType={cameFrom == 'rebooks' ? 'Call' : 'Note'}
                            onAdd={async () => {
                                if (cameFrom == 'rebooks') {
                                    this.close();
                                    this.resolve({ action: 'add-customer-comm' });
                                } else {
                                    await this.loadRebooks(this.state.customer)     
                                }
                            }}
                        />
                    }

                </div>

                <div className="col-md-6">

                    <section>

                        {isLoadingProperties ? <Loader /> :
                            isIndividual ? this.renderIndividual() :
                                this.renderBusiness()
                        }

                    </section>
                    
                    <div style={{ marginTop: isIndividual ? "0": "15px"}}>
                        {this.renderRebooksSection()}    
                    </div>

                    {!!customer.id && customer.property && isIndividual &&
                        <ApptList
                            {...this.props}
                            customerID={customer.id}
                            propertyID={customer.property.id}
                            hideButtons={cameFrom == 'appt'}
                        />
                    }

                </div>

            </div>

            <EditPropertyModal
                history={history}
                ref={this.editPropertyModalRef}
                cameFrom={cameFrom}
                onSwitch={accountID => this.switchAccount(accountID)}
                {...this.props}
            />
            <DoNotSweepModal ref={this.doNotSweepModalRef} />
            <MovePropertyModal ref={this.movePropertyModalRef} />
        </>);
    }

    renderControls(showTitle) {
        const { isLoading, customer, cameFrom } = this.state;
        if (isLoading) return null;

        const isIndividual = ReferenceHelpers.isIndividual(customer.type);
        let title = 'New Customer';
        if (customer.id) {
            if (isIndividual || !customer.companyName) {
                title = TextHelpers.formatName(customer.title, customer.firstName, customer.lastName);
            } else {
                title = customer.companyName;
            }
        }

        return (<>

            <button type="button" className="btn btn-secondary me-3" onClick={() => this.close()}>
                Cancel
            </button>

            <button type="button" className="btn btn-secondary me-auto" onClick={() => this.showPriceCalcModal()}>
                <span className="icon fa-regular fa-clipboard" />{' '}Quotation
            </button>

            {showTitle &&
                <Modal.Title>
                    {title}
                </Modal.Title>
            }

            <div className="ms-auto">

                {!!customer.id && <>

                    <div className="dropdown me-3">
                        <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            More...
                        </button>
                        <ul className="dropdown-menu">
                            {customer.property && !!customer.property.latestApptID && <>
                                <li>
                                    <a className="dropdown-item" href="#" onClick={e => {
                                        e.preventDefault();
                                        this.getLatestCertificate(true);
                                    }}>
                                        <span className="fa-solid fa-certificate" />{' '}
                                        Print latest cert
                                        {customer.property.latestApptDate && <>
                                            {' '}({moment(customer.property.latestApptDate).format('DD/MM/YYYY')})
                                        </>}
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#" onClick={e => {
                                        e.preventDefault();
                                        this.getLatestCertificate(false);
                                    }}>
                                        <span className="fa-solid fa-download" />{' '}
                                        Download latest cert
                                        {customer.property.latestApptDate && <>
                                            {' '}({moment(customer.property.latestApptDate).format('DD/MM/YYYY')})
                                        </>}
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#" onClick={e => {
                                        e.preventDefault();
                                        this.confirmResendCompletedNotification();
                                    }}>
                                        <span className="fa-solid fa-certificate" />{' '}
                                        Email certificate
                                    </a>
                                </li>
                                <li className="dropdown-divider" />
                            </>}
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.showDoNotSweepModal();
                                }}>
                                    <span className="fa-solid fa-ban" />{' '}
                                    Do not sweep
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.confirmToggleIsArchived();
                                }}>
                                    <span className="fa-solid fa-box-archive" />{' '}
                                    {customer.isArchived ? 'Un-archive' : 'Archive'}
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.confirmDelete();
                                }}>
                                    <span className="fa-solid fa-trash" />{' '}
                                    Delete
                                </a>
                            </li>
                            {!!customer.id &&
                                <li>
                                    <a className="dropdown-item" href="#" onClick={e => {
                                        e.preventDefault();
                                        this.showDedupeModal();
                                    }}>
                                        <span className="fa-solid fa-copy" />{' '}
                                        De-duplicate
                                    </a>
                                </li>
                            }
                            {customer.property && 
                                <li>
                                    <a className="dropdown-item" href="#" onClick={e => {
                                        e.preventDefault();
                                        this.showMovePropertyToOtherCustomerModal();
                                    }}>
                                        <span className="fa-solid fa-right-to-bracket" />{' '}Move property to different customer
                                    </a>
                                </li>
                            }
                        </ul>
                    </div>
                </>}

                <button type="type" className="btn btn-secondary me-3" onClick={() => this.addAppt()}>
                    Add Appointment
                </button>

                <button type="type" className="btn btn-secondary me-3" onClick={() => this.saveAndReload()}>
                    Save
                </button>

                <button type="submit" className="btn btn-primary">
                    Save & Close
                </button>

            </div>
        </>);
    }

    renderCustomerDetails(isIndividual) {
        return (<>

            <div className="row">

                <div className="col-md-2">

                    {this.form.render('title')}

                </div>

                <div className="col-md-3">

                    {this.form.render('firstName')}

                </div>

                <div className="col-md-3">

                    {this.form.render('lastName')}

                </div>

                <div className="col-md-4">

                    {this.form.render('type')}

                </div>

            </div>

            {!isIndividual && this.form.render('companyName')}

            <div className="row">

                <div className="col-md-3">

                    {this.form.render('customerSourceID')}

                </div>

                <div className="col-md-3">

                    {this.form.render('landlineTel')}

                </div>

                <div className="col-md-3">

                    {this.form.render('mobileTel')}

                </div>

                <div className="col-md-3">

                    {this.form.render('emailAddress')}

                </div>
            </div>

            {this.form.render('notes')}

        </>);
    }

    renderIndividual() {
        const { customer } = this.state;

        return (<>

            <h2>
                Property Details
            </h2>

            <EditProperty
                {...this.props}
                property={customer.property || {}}
                updateFields={(fields, callback) => this.updatePropertyFields(fields, callback)}
                onSwitch={accountID => this.switchAccount(accountID)}
                onReload={() => this.load()}
            />

        </>);
    }

    renderBusiness() {
        const {
            customer,
            properties
        } = this.state;

        return (<>
            <h2>
                Billing Address
            </h2>

            <EditAddress
                address={customer.billingAddress || {}}
                updateFields={(fields, callback) => this.updateBillingAddressFields(fields, callback)}
            />

            <h2 className="mt-3">
                Properties

                <button type="button" className="ms-auto btn" onClick={() => this.addProperty()}>
                    <span className="fa-solid fa-plus" />{' '}
                    Add Property
                </button>
            </h2>

            {/* TODO move style into css */}
            <div className="sticky-table-container" style={{
                maxHeight: 'calc(100vh - 485px)'
            }}>
                <SuperTable
                    className="customer-properties-table table table-bordered clickable mb-0"
                    rows={properties}
                    keyAccessor={property => property.id}
                    cols={{
                        name: {
                            label: 'Name'
                        },
                        tenantName: {
                            label: 'Tenant'
                        },
                        rebook: {
                            label: 'Rebook',
                            getValue: (col, property) =>
                                Object.keys(property.rebookMonths).map(m => moment().month(m-1).format('MMM')).join(', ')
                        }
                    }}
                    onClick={(property, e, index) => this.editProperty(property.id)}
                    emptyText="No properties added"
                />
            </div>
        </>);
    }
}

export default EditCustomerModal;