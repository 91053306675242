import React from 'react';

// Services & Helpers
import API from 'API';

// Components
import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';
import PageList from 'components/common/PageList';
import ResendEmailModal from 'components/outgoing/ResendEmailModal';

//-----------------------------------------------------------------

class CustomerCommListPage extends React.Component {

    constructor(props) {
        super(props);

        this.resendEmailModalRef = React.createRef();

        this.state = {
            isLoading: true,
            customerComms: []
        };
    }

    componentDidMount() {
        this.loadPages();

        this.props.fns.setTitle('Comms');
    }

    async loadPages() {
        // Load pages
        this.setState({ isLoadingPages: true });
        let pages = await API.call('customer-comm/list-pages', {
            types: ['Email', 'SMS']
        });
        pages = [{
            id: 'failed-automated',
            label: 'Failed automated (last 3 months)'
        }].concat(pages);

        // Update UI
        this.setState({
            isLoadingPages: false,
            pages,
            page: 'failed-automated'
        }, () => {
            this.load();
        });
    }
    
    async load() {
        const { page, thisAccountOnly } = this.state;
        this.setState({ isLoading: true });
        const param = {
            types: ['Email', 'SMS'],
            thisAccountOnly
        };
        if (page == 'failed-automated') {
            param.page = page;
        } else {
            param.month = `${page}-01`;
        }
        const customerComms = await API.call('customer-comm/list', param);
        this.setState({
            isLoading: false,
            customerComms
        });
    }

    async open(cc) {
        if (cc.type == 'Email') {
            await this.resendEmailModalRef.current.open({
                id: cc.id
            });
            this.load();
        }
    }

    //----------------------------------------------------------------------

    render() {
        const {
            isLoadingPages,
            isLoading,
            pages,
            page,
            thisAccountOnly
        } = this.state;
        const { loginInfo } = this.props;

        if (isLoadingPages || isLoading) {
            return (<Loader />);
        }

        return (<>

            <section className="control-panel">

                <PageList
                    pages={pages}
                    page={page}
                    getURL={(page) => '#'}
                    onClick={(page) => this.setState({ page }, () => this.load())}
                />

            </section>

            {loginInfo.accounts.length > 1 &&
                <section className="control-panel">

                    <div className="form-check">
                        <label>
                            <input
                                type="checkbox"
                                checked={thisAccountOnly || false}
                                onChange={e => this.setState({
                                    thisAccountOnly: e.target.checked
                                }, () => this.load())}
                            />{' '}
                            This account only
                        </label>
                    </div>

                </section>
            }

            <section>

                {this.renderTable()}

            </section>

            <ResendEmailModal ref={this.resendEmailModalRef} />

        </>);
    }

    renderTable() {
        const {
            isLoading,
            customerComms,
            thisAccountOnly
        } = this.state;
        const { loginInfo } = this.props;

        if (isLoading) {
            return (<Loader />);
        }

        const cols = {
            accountName: {
                label: 'Account'
            },
            dateTime: {
                label: 'Sent on',
                type: 'dateTime'
            },
            type: {
                label: 'Type'
            },
            recipient: {
                label: 'Recipient'
            },
            subject: {
                label: 'Subject / Message',
                className: 'subject-col'
            },
            error: {
                label: 'Error',
                className: 'error-col'
            }
        };

        if (loginInfo.accounts.length <= 1 || thisAccountOnly) {
            delete cols.accountName;
        }

        return (
            <SuperTable
                className="customer-comms-table table table-bordered clickable mb-0"
                rows={customerComms}
                keyAccessor={cc => cc.id}
                cols={cols}
                onClick={(cc, e) => this.open(cc)}
                emptyText="No outgoing email / SMS to show"
            />
        );
    }
}

export default CustomerCommListPage;