import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import API from 'API';
import FormHelper from 'helpers/FormHelper';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditAccountModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
        this.form = new FormHelper({
            fields: {
                name: {
                    label: 'Name',
                    type: 'text'
                },
                territoryNumber: {
                    label: 'Territory Number'
                },
                isBookingAgent: {
                    boxLabel: 'Booking agent',
                    type: 'checkbox'
                },
                isTrialMode: {
                    boxLabel: 'Trial mode (disable outgoing comms)',
                    type: 'checkbox'
                }
            },
            getValue: (fieldName) => this.state.account[fieldName],
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                id: opt.id
            }, () => {
                this.load();
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async load() {
        return new Promise(async (resolve, reject) => {
            const { id } = this.state;
            let account;

            if (id) {
                this.setState({ isLoading: true });
                account = await API.call(`account/get/${id}`);
                if (!account) {
                    alert('Sorry, the account could not be found');
                    this.close();
                    return;
                }
            } else {
                account = {
                };
            }

            // Update UI
            this.setState({
                isLoading: false,
                account
            });
        });
    }

    async save() {
        return new Promise(async (resolve) => {
            let { account } = this.state;
            this.setState({ isLoading: true });
            this.changedFields = {};
            const { id, code } = await API.call('head-office/save-account', account);
            this.setState({ isLoading: false });
            resolve({ action: 'save', id, code });
        });
    }

    async saveAndClose() {
        const result = await this.save();
        this.close();
        this.resolve(result);
    }

    async saveAndSetUp() {
        const { code } = await this.save();
        this.close();
        window.location = `/${code}/setup/account`;
    }

    updateFields(fields) {
        const account = { ...this.state.account };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            account[fieldName] = value;
        }
        this.setState({ account });
    }

    async confirmDelete() {
        const confirm = window.confirm('Are you sure you want to delete this account?');
        if (confirm) {
            this.setState({ isLoading: true });
            await API.call('head-office/delete-account/' + this.state.account.id);
            this.close();
            this.resolve({ action: 'delete' });
        }
    }

    //--------------------------------------------------------------------------

    render() {
        const { 
            isOpen,
            isLoading,
            account
        } = this.state;

        if (!isOpen) {
            return null;
        }
       
        return (
            <Modal show onHide={() => this.close()} className="edit-account-modal" backdrop="static">
                <form onSubmit={e => { e.stopPropagation(); e.preventDefault(); this.saveAndClose() }}>
                    <Modal.Header>
                        <Modal.Title>Edit account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.renderInner()}
                    </Modal.Body>
                    <Modal.Footer>

                        {isLoading ? <Loader /> : <>

                            <button type="button" className="btn btn-secondary me-3" onClick={() => this.close()}>
                                Cancel
                            </button>

                            {!account.isHeadOffice &&
                                <button type="button" className="btn btn-danger" onClick={() => this.confirmDelete()}>
                                    Delete
                                </button>
                            }

                            <button type="button" className="btn btn-secondary ms-auto" onClick={() => this.saveAndClose()}>
                                <span className="icon fa fa-check" />{' '}Save & close
                            </button>

                            <button type="button" className="btn btn-primary" onClick={() => this.saveAndSetUp()}>
                                <span className="icon fa fa-chevron-right" />{' '}Save & set up 
                            </button>

                        </>}

                    </Modal.Footer>
                </form>
            </Modal>
        );
    }

    renderInner() {
        const {
            isLoading
        } = this.state;
        const { history } = this.props;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            {this.form.render([
                'name',
                'territoryNumber',
                'isBookingAgent',
                'isTrialMode'
            ])}

        </>);
    }

}

export default EditAccountModal;