// Libs
import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/en-gb';

// Helpers
import TextHelpers from 'helpers/TextHelpers';

//-------------------------------------------------------------------------------------------------------------------

class SuperTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.buildGroupings(this.props.rows);
    }

    componentDidUpdate(oldProps) {
        let buildGroupings = false;
        if (this.props.rows.length != oldProps.rows.length) {
            buildGroupings = true;
        } else {
            for (var i = 0; i < oldProps.rows.length; i++) {
                const oldRow = oldProps.rows[i];
                const newRow = this.props.rows[i];
                if (oldRow._grouping != newRow._grouping) {
                    buildGroupings = true;
                    break;
                }
            }
        }
        if (buildGroupings) {
            this.buildGroupings(this.props.rows);
        }
    }

    buildGroupings(rows) {
        const groupings = {};
        rows.forEach(row => {
            if (row._grouping) {
                if (!groupings[row._grouping]) {
                    groupings[row._grouping] = {
                        isOpen: false,
                        rows: []
                    };
                }
            }
        });
        this.setState({
            groupings
        });
    }

    toggleGrouping(key) {
        const groupings = { ...this.state.groupings };
        const grouping = groupings[key];
        if (grouping) {
            grouping.isOpen = !grouping.isOpen;
            this.setState({
                groupings
            });
        }
    }

    formatValue(value, type, col) {
        switch ((type || '').toLowerCase()) {
            case 'currency':
                if (value) {
                    return TextHelpers.formatCurrency(value);
                }
            //{
            //    numDP: 2,
            //    includeSymbol: true,
            //    commas: true,
            //    blankIfZero: (col && col.blankIfZero)
            //});
            case 'date':
                if (value) {
                    return moment(value).format('DD/MM/YYYY');
                }
                break;
            case 'datetime':
                if (value) {
                    return moment(value).format('DD/MM/YYYY HH:mm');
                }
                break;
            case 'percentage':
                if (value) {
                    return Math.round(Number(value) * 10000) / 100 + '%';
                } else {
                    return (col && col.blankIfZero ? '' : '0%');
                }
            case 'duration':
                if (value) {
                    return TextHelpers.formatDuration(Number(value));
                }
                break;
            case 'boolean':
                if(value !== undefined){
                    console.log(value);
                    return value === true ? "Yes" : "No";
                }
                break;
            default:
                return value;
        }
        return '';
    }

    render() {
        let {
            className,
            rows,
            cols,
            keyAccessor,
            sorter,
            onClick,
            rowPropsAccessor,
            cellPropsAccessor,
            emptyText,
            sortBy,
            sortDesc,
            hideHeader
        } = this.props;
        const {
            groupings
        } = this.state;

        if (!rows) {
            rows = [];
            console.warn('No rows specified for <SuperTable>');
        }
        if (!cols) {
            cols = {};
            console.warn('No cols specified for <SuperTable>');
        }

        if (sorter) {
            rows.sort(sorter);
        } else if (sortBy) {
            if (cols[sortBy].sort) {
                rows.sort(cols[sortBy].sort);
                if (sortDesc) {
                    rows.reverse();
                }
            } else {
                let getDiff;
                switch (cols[sortBy].type) {
                    case 'date':
                        getDiff = (a, b) => {
                            a = a[sortBy];
                            b = b[sortBy];
                            const diff = moment.duration(moment(b).diff(moment(a))).asMinutes();
                            return Math.sign(diff);
                        }
                        break;
                    default:
                        getDiff = (a, b) => {
                            a = a[sortBy];
                            b = b[sortBy];
                            if (a > b) return 1;
                            else if (a < b) return -1;
                            else return 0;
                        };
                        break;
                }
                rows.sort((a, b) => getDiff(a, b) * (sortDesc ? -1 : 1));
            }
        }

        // Convert cols to an array
        const colArray = [];
        let index = 0;
        for (var name in cols) {
            if (cols.hasOwnProperty(name)) {
                const col = cols[name];
                if (col.hide) continue;
                colArray.push({
                    name: name,
                    sortOrder: col.sortOrder || index,
                    col: col,
                    index: index++
                });
            }
        }
        colArray.sort((a, b) => a.sortOrder - b.sortOrder);

        if (onClick) {
            className = (className || '') + ' clickable';
        }

        if (rows.length == 0) {
            return (
                <div className="empty-text">
                    {emptyText}
                </div>
            );
        }

        // If groupings not build yet, skip render - groupings will be built and then render will be called again
        if (!groupings) {
            return null;
        }
        
        // Create elements for each row
        const results = [];
        const groupingsRendered = {};
        rows.forEach((row, index) => {
            const id = String((keyAccessor ? keyAccessor(row, index) : null) || index);
            const rowProps = {
                onClick: e => onClick ? onClick(row, e) : null,
                ...(rowPropsAccessor ? rowPropsAccessor(row, index) : null)
            };

            // Render grouping header
            if (row._grouping && groupings[row._grouping]) {
                if (!groupingsRendered[row._grouping]) {
                    groupingsRendered[row._grouping] = true;
                    
                    results.push(
                        <tr
                            key={'grouping-' + row._grouping}
                            className="grouping-row"
                            onClick={e => this.toggleGrouping(row._grouping)}
                        >
                            <td colSpan={colArray.length}>
                                {groupings[row._grouping].isOpen ?
                                    <span className="fa fa-minus-circle" /> :
                                    <span className="fa fa-plus-circle" />
                                }
                                {row._grouping}
                            </td>
                        </tr>
                    );
                }
                groupings[row._grouping].rows.push(row);
            }

            // Don't show any more if grouping is closed
            if (row._grouping && groupings[row._grouping] && !groupings[row._grouping].isOpen) {
                return;
            }

            /*
            results.push(
                <tr
                    key={id}
                    {...rowProps}
                    className={(rowProps.className || '')}
                >
                    {colArray.map((colInfo, colIndex) =>
                        <React.Fragment key={colInfo.name}>
                            {(colInfo.col.renderCell || ((colInfo, row, colIndex) =>
                                <TableCell
                                    key={colInfo.name}
                                    className={colInfo.className || ''}
                                    {...(cellPropsAccessor ? cellPropsAccessor(colInfo, row, index) : null)}
                                >
                                    {getContent(colInfo, row, index, colIndex)}
                                </TableCell>
                            ))(colInfo, row, colIndex)}
                        </React.Fragment>
                    )}
                </tr>
            );*/

            results.push(
                <tr key={keyAccessor ? keyAccessor(row, index) : index} onClick={e => onClick ? onClick(row, e, index) : null} {...(rowPropsAccessor ? rowPropsAccessor(row, index) : null)}>
                    {colArray.map((colInfo, colIndex) => {
                        const shouldRenderCell = this.props.shouldRenderCell ? this.props.shouldRenderCell(colInfo, row, index) : true;
                        if (!shouldRenderCell) {
                            return null;
                        }
                        const formattedValue = this.formatValue(row[colInfo.name], colInfo.col.type, colInfo.col);
                        return (
                            <React.Fragment key={colInfo.name}>
                                {(colInfo.col.renderCell || ((colInfo, row) =>
                                    <td className={colInfo.col.className || ''} {...(cellPropsAccessor ? cellPropsAccessor(colInfo, row, index, colIndex) : null)}>
                                        {colInfo.col.getValue ? colInfo.col.getValue(colInfo, row, formattedValue, index) : formattedValue}
                                    </td>
                                ))(colInfo, row)}
                            </React.Fragment>
                        );
                    })}
                </tr>
            );
        });

        return (<>
            <div className="table-container">
                <table className={'super-table ' + (className || '')}>
                    {!hideHeader &&
                        <thead>
                            <tr>
                                {colArray.map(colInfo =>
                                    <React.Fragment key={colInfo.name}>
                                        {(colInfo.col.renderHeaderCell || this.props.renderHeaderCell)(colInfo, this.props)}
                                    </React.Fragment>
                                )}
                            </tr>
                        </thead>
                    }
                    <tbody>
                        {results}
                    </tbody>
                </table>
            </div>
        </>);
    }
}

SuperTable.defaultProps = {
    renderHeaderCell: (colInfo, props) => {
        const classNames = [colInfo.col.className || ''];
        if (colInfo.col.sortable) {
            classNames.push('sortable');
            if (colInfo.name == props.sortBy) {
                classNames.push('sort-by');
                if (props.sortDesc) {
                    classNames.push('desc');
                }
            }
        }
        return (
            <th className={classNames.join(' ')} onClick={e => {
                if (colInfo.col.sortable) {
                    const sortDesc = (colInfo.name == props.sortBy ? !props.sortDesc : false);
                    props.onChangeSort(colInfo.name, sortDesc);
                }
            }}>
                {colInfo.col.label}

                {colInfo.name == props.sortBy && (
                    props.sortDesc ?
                        <span className="fa fa-sort-down"></span> :
                        <span className="fa fa-sort-up"></span>
                )}
            </th>
        );
    }
};

export default SuperTable;