import React from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import ExpandingTextArea from 'react-expanding-textarea';

// Services & Helpers
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';

// Components
import Loader from 'components/common/Loader';
import CurrencyInput from 'components/common/CurrencyInput';

//-----------------------------------------------------------------

class EditApptResultsModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                id: opt.id,
                ...opt
            }, () => {
                this.load();
            });
        });
    }

    async load() {
        this.setState({ isLoading: true });

        // Load appt results
        const appt = await API.call(`appt/get-results/${this.state.id}`);
        const property = await API.call(`property/get/${appt.propertyID}`);

        // Load related entities
        const products = await API.call('product/list', { isSold: true });
        let productsByCategory = {};
        products.forEach(p => {
            if (!productsByCategory[p.category]) {
                productsByCategory[p.category] = {
                    name: p.category,
                    products: []
                };
            }
            productsByCategory[p.category].products.push(p);
        });
        productsByCategory = Object.values(productsByCategory);

        const serviceTypes = await API.call('service-type/list');
        const serviceTypesByCode = {};
        const serviceTypesByID = {};
        serviceTypes.forEach(st => {
            serviceTypesByCode[st.code] = st;
            serviceTypesByID[st.id] = st;
        });

        // Update UI
        this.setState({
            appt,
            property,
            products,
            productsByCategory,
            serviceTypesByID,
            isLoading: false
        });
    }

    updateResult(id, fields) {
        const services = [...this.state.appt.services];
        const index = services.findIndex(ast => ast.id == id);
        const apptServiceType = { ...services[index] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            apptServiceType[fieldName] = value;
        }
        services[index] = apptServiceType;
        this.updateFields({ services });
    }

    updateFields(fields) {
        const appt = { ...this.state.appt };
        let anyChanged = false;

        for (let fieldName in fields) {
            const oldValue = appt[fieldName];
            const value = fields[fieldName];
            if (value != oldValue) {
                anyChanged = true;
                appt[fieldName] = value;
                
                switch (fieldName) {
                    case 'products':
                        this.updateTotal(appt);
                        break;
                }
            }
        }

        if (anyChanged) {
            this.setState({ appt });
            this.isChanged = true;
        }
    }

    addProduct() {
        const { appt } = this.state;
        const products = [...appt.products];
        let id = 0;
        products.forEach(ap => {
            if (ap.id < id) id = ap.id;
        });
        id--;
        products.push({
            id,
            date: moment().startOf('day').toDate(),
            isDeposit: (appt.status == 'Open')
        });
        this.focusInput = `product-${id}`;
        this.updateFields({ products });
    }

    updateProduct(id, fields) {
        const products = [...this.state.appt.products];
        const index = products.findIndex(ap => ap.id == id);
        const product = { ...products[index] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            product[fieldName] = value;
        }
        product.totalPrice = (Number(product.unitPrice) || 0) * (Number(product.quantity) || 0);
        products[index] = product;
        this.updateFields({ products });
    }

    confirmRemoveProduct(id) {
        const confirm = window.confirm('Are you sure you want to remove this product?');
        if (confirm) {
            const products = [...this.state.appt.products];
            const index = products.find(p => p.id == id);
            products.splice(index, 1);
            this.updateFields({ products });
        }
    }

    updateTotal(appt) {
        appt.productPrice = 0;
        appt.products.forEach(p => {
            appt.productPrice += (p.totalPrice || 0);
        });
    }

    async saveAndClose() {
        const { appt } = this.state;
        this.setState({ isLoading: true });
        if (this.isChanged) {
            await API.call('appt/save-results', appt);
        }
        this.close();
        this.resolve({
            productPrice: appt.productPrice
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    //--------------------------------------------------------------------------

    render() {
        const { 
            isOpen,
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="edit-appt-results-modal" backdrop="static" keyboard={false}>
                <form onSubmit={e => { e.preventDefault(); e.stopPropagation(); this.saveAndClose() }} className="edit-appt-form">
                    {this.renderInner()}
                </form>
            </Modal>
        );
    }

    renderInner() {
        const { isLoading, appt, serviceTypesByID } = this.state;
        if (isLoading) {
            return (
                <Modal.Body>
                    <Loader />
                </Modal.Body>
            );
        }

        return (<>
            <Modal.Header>
                <Modal.Title>Appointment results</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {this.renderServices()}

                {this.renderProducts()}

                <div className="row">

                    <div className="col-md-6">

                        <div className="form-group">
                            <label className="form-label">Payment type</label>
                            <select
                                className="form-control"
                                value={appt.paymentType || ''}
                                onChange={e => {
                                    this.updateFields({ paymentType: e.target.value });
                                }}
                            >
                                <option value="">(Select)</option>
                                <option value="PayNow">Pay now</option>
                                <option value="PaymentToFollow">Payment to follow</option>
                                <option value="SendInvoice">Send invoice</option>
                            </select>
                        </div>

                    </div>

                    <div className="col-md-6">

                        <div className="form-group">
                            <label className="form-label">Certificate action</label>
                            <select
                                className="form-control"
                                value={appt.certAction || ''}
                                onChange={e => {
                                    this.updateFields({ certAction: e.target.value });
                                }}
                            >
                                <option value="">(Select)</option>
                                <option value="Email">Email</option>
                                <option value="EmailUponPayment">Email upon payment</option>
                                <option value="SendManually">Send manually</option>
                                <option value="NotNeeded">Not needed</option>
                            </select>
                        </div>

                    </div>

                </div>

                <div className="row">

                    <div className="col-md-6">

                        <div className="form-group">
                            <label className="form-label">Customer present</label>
                            <select
                                className="form-control"
                                value={`${appt.isCustomerPresent}`}
                                onChange={e => {
                                    this.updateFields({ isCustomerPresent: (e.target.value == 'true' ? true : (e.target.value == 'false' ? false : null)) });
                                }}
                            >
                                <option value="">(Select)</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>

                    </div>

                    <div className="col-md-6">

                        {appt.isCustomerPresent && appt.signature &&
                            <div className="form-group">
                                <label className="form-label">Signature</label>
                                <img src={appt.signature} className="signature" />
                            </div>
                        }

                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                    Close
                </button>
                <button type="submit" className="btn btn-primary ms-auto">
                    Save
                </button>
            </Modal.Footer>
        </>);
    }

    renderServices() {
        const { appt, property, serviceTypesByID } = this.state;

        return (

            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Service</th>
                        <th>Result</th>
                    </tr>
                </thead>
                <tbody>
                    {appt.services.map((ast, index) => {
                        const serviceType = serviceTypesByID[ast.serviceTypeID];
                        const chimney = property.chimneys.find(c => c.id == ast.chimneyID);
                        if (!chimney) return null;

                        return (
                            <tr key={index}>
                                <td>
                                    {serviceType && <strong>{serviceType.name}</strong>}
                                    {chimney && <>
                                        <br />
                                        {chimney.chimneyType.name || 'Unspecified'} <br />
                                        {chimney.floor}{' '}floor{' '}{chimney.location}
                                    </>}

                                </td>
                                <td>

                                    {this.renderService(ast)}

                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
    
    renderService(ast) {
        if (!ast.serviceType) return null;
        const serviceTypeCode = ast.serviceType.code;

        return (<>

            <div className="form-group">
                <label className="form-label">Status</label>
                <select
                    className="form-control"
                    value={ast.status || ''}
                    onChange={e => this.updateResult(ast.id, { status: e.target.value })}
                >
                    <option value="">(Select)</option>
                    <option value="OK">OK</option>
                    <option value="Fail">Fail</option>
                    {(serviceTypeCode == 'sweep' || serviceTypeCode == 'nest') &&
                        <option value="Condemn">Condemn</option>
                    }
                </select>
            </div>

            {ast.status == 'Fail' && <>
                <div className="form-group">
                    <label className="form-label">Fail reason</label>
                    <select
                        className="form-control"
                        value={ast.failReason || ''}
                        onChange={e => this.updateResult(ast.id, { failReason: e.target.value })}
                    >
                        <option value="">(Select)</option>
                        <option value="NoAccess">No access</option>
                        <option value="NestInFlue">Nest in flue</option>
                        <option value="MasonryInFlue">Masonry in flue</option>
                        <option value="ExternalAccessOnly">External access only</option>
                        <option value="RoofAccessRequired">Roof access required</option>
                        <option value="Other">Other</option>
                    </select>
                </div>

                <div className="form-group">
                    <label className="form-label">Failure notes</label>
                    <ExpandingTextArea
                        className="form-control"
                        key={ast.failReasonNotes || ''}
                        defaultValue={ast.failReasonNotes || ''}
                        onBlur={e => this.updateResult(ast.id, { failReasonNotes: e.target.value })}
                        rows={2}
                    />
                </div>

            </>}

            {(serviceTypeCode == 'sweep' || serviceTypeCode == 'nest') && <>
                <div className="form-group">
                    <label className="form-label">CO alarm</label>
                    <select
                        className="form-control"
                        value={ast.coAlarmStatus || ''}
                        onChange={e => this.updateResult(ast.id, { coAlarmStatus: e.target.value })}
                    >
                        <option value="">(Not applicable)</option>
                        <option value="Working">Fitted & working</option>
                        <option value="NotTested">Fitted, not tested</option>
                        <option value="NotWorking">Fitted, not working</option>
                        <option value="NotFitted">Not fitted</option>
                    </select>
                </div>

                <div className="form-check mb-2">
                    <input
                        id="isCOAlarmLocationCorrect"
                        className="form-check-input"
                        type="checkbox"
                        checked={ast.isCOAlarmLocationCorrect || ''}
                        onChange={e => this.updateResult(ast.id, { isCOAlarmLocationCorrect: e.target.checked })}
                    />
                    <label className="form-check-label" htmlFor="isCOAlarmLocationCorrect">
                        CO alarm location correct
                    </label>
                </div>

                <div className="form-group">
                    <label className="form-label">Draw test</label>
                    <select
                        className="form-control"
                        value={ast.isDrawTestPassed === true ? 'true' : (ast.isDrawTestPassed === false ? 'false' : '')}
                        onChange={e => this.updateResult(ast.id, {
                            isDrawTestPassed: (e.target.value == 'true' ? true : e.target.value == 'false' ? false : null)
                        })}
                    >
                        <option value="">(Not performed)</option>
                        <option value="true">Pass</option>
                        <option value="false">Fail</option>
                    </select>
                </div>

                {ast.isDrawTestPassed === false &&
                    <div className="form-group">
                        <label className="form-label">Draw test fail reason</label>
                        <input
                            type="text"
                            className="form-control"
                            value={ast.drawTestFailReason || ''}
                            onChange={e => this.updateResult(ast.id, { drawTestFailReason: e.target.value })}
                        />
                    </div>
                }

                <div className="form-check">
                    <input
                        id="isFlueTerminalFitted"
                        className="form-check-input"
                        type="checkbox"
                        checked={ast.isFlueTerminalFitted || ''}
                        onChange={e => this.updateResult(ast.id, { isFlueTerminalFitted: e.target.checked })}
                    />
                    <label className="form-check-label" htmlFor="isFlueTerminalFitted">
                        Appropriate flue terminal fitted
                    </label>
                </div>

                <div className="form-group mt-2">
                    <label className="form-label">Recommendations</label>

                    {/*
                    <div className="form-check">
                        <input
                            id="isPowerSweepRecommended"
                            className="form-check-input"
                            type="checkbox"
                            checked={ast.isPowerSweepRecommended || ''}
                            onChange={e => this.updateResult(ast.id, { isPowerSweepRecommended: e.target.checked })}
                        />
                        <label className="form-check-label" htmlFor="isPowerSweepRecommended">
                            Power Sweep
                        </label>
                    </div>
                    */}

                    <div className="form-check">
                        <input
                            id="isFlueFreeRecommended"
                            className="form-check-input"
                            type="checkbox"
                            checked={ast.isFlueFreeRecommended || ''}
                            onChange={e => this.updateResult(ast.id, { isFlueFreeRecommended: e.target.checked })}
                        />
                        <label className="form-check-label" htmlFor="isFlueFreeRecommended">
                            HotSpot Flue Free
                        </label>
                    </div>
                </div>

                <div className="form-group">
                    <label className="form-label">Next sweep in (months)</label>
                    <input
                        type="number"
                        className="form-control"
                        value={ast.nextSweepInMonths || ''}
                        onChange={e => this.updateResult(ast.id, { nextSweepInMonths: e.target.value })}
                    />
                </div>

            </>}

            <div className="form-group mb-0">
                <label className="form-label">Other comments</label>
                <ExpandingTextArea
                    className="form-control"
                    key={ast.notes || ''}
                    defaultValue={ast.notes || ''}
                    onBlur={e => this.updateResult(ast.id, { notes: e.target.value })}
                    rows={2}
                />
            </div>

        </>);
    }

    renderProducts() {
        const { appt, productsByCategory } = this.state;

        let table;
        if (appt.products.length == 0) {
            table = (
                <p className="form-control-plaintext">
                    No products added
                </p>
            );
        } else {
            table = (
                <table className="table table-bordered appt-products-table">
                    <thead>
                        <tr>
                            <th className="product-col">Product</th>
                            <th className="unit-price-col">Unit Price</th>
                            <th className="qty-col">Qty</th>
                            <th className="total-price-col">Total</th>
                            <th className="actions-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {appt.products.map(ap =>
                            <tr key={ap.id}>
                                <td className="product-col">
                                    <select
                                        className="form-control"
                                        value={ap.productID || ''}
                                        onChange={e => this.updateProduct(ap.id, { productID: e.target.value })}
                                    >
                                        {!ap.productID &&
                                            <option value="new">(Select)</option>
                                        }
                                        {productsByCategory.map(pc =>
                                            <optgroup label={pc.name || 'No category'} key={pc.name || ''}>
                                                {pc.products.map(p =>
                                                    <option key={p.id} value={p.id}>
                                                        {p.name}
                                                    </option>
                                                )}
                                            </optgroup>
                                        )}
                                        <option value="">Other</option>
                                    </select>
                                    {!ap.isNew && !ap.productID &&
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={ap.name || ''}
                                            onChange={e => this.updateProduct(ap.id, { name: e.target.value })}
                                        />
                                    }
                                </td>
                                <td className="unit-price-col">
                                    <CurrencyInput
                                        className="form-control"
                                        value={ap.unitPrice || ''}
                                        onValueChange={(values) => {
                                            const unitPrice = Number(values.value) || '';
                                            this.updateProduct(ap.id, { unitPrice });
                                        }}
                                    />
                                </td>
                                <td className="qty-col">
                                    <input
                                        className="form-control"
                                        type="number"
                                        value={ap.quantity || ''}
                                        onChange={e => this.updateProduct(ap.id, { quantity: e.target.value })}
                                    />
                                </td>
                                <td className="total-price-col">
                                    {TextHelpers.formatCurrency(ap.totalPrice)}
                                </td>
                                <td className="actions-col">

                                    <button type="button" className="btn btn-danger" onClick={() => this.confirmRemoveProduct(ap.id)}>
                                        <span className="fa fa-times" />
                                    </button>

                                </td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Total</td>
                            <td className="price-col">
                                {TextHelpers.formatCurrency(appt.productPrice || '')}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            );
        }

        return (
            <div className="form-group">
                <label className="form-label">
                    Products

                    <button type="button" className="btn btn-primary ms-auto" onClick={() => this.addProduct()}>
                        <span className="fa-solid fa-plus" />{' '}
                        Add Product
                    </button>
                </label>
                {table}
            </div>
        );
    }

}

export default EditApptResultsModal;