import React from 'react';

class TimeInput extends React.Component {

    render() {
        const props = {
            ...this.props,
            defaultValue: this.props.value,
            key: this.props.value
        };
        const onChange = props.onChange;
        delete props.onChange;
        delete props.value;

        return (
            <input
                type="text"
                key={props.value}
                defaultValue={props.value}
                onFocus={e => {
                    this.lastValue = e.target.value;
                }}
                onBlur={e => {
                    let value = e.target.value;
                    const regex = new RegExp(/([0-9]{1,2})([\.:,_\- ]?)([0-9]{0,2})/g);
                    const match = regex.exec(value);
                    if (match) {
                        let hours = parseInt(match[1]) || 0;
                        let mins = parseInt(match[3]) || 0;

                        // If they write, e.g. 620, make sure that ends up as 6:20 not 62:0
                        if (hours > 24) {
                            mins = parseInt(`${hours % 10}${mins}`);
                            hours = Math.floor(hours / 10);
                        }

                        hours = (hours < 10 ? `0${hours}` : `${hours}`);
                        mins = (mins < 10 ? `0${mins}` : `${mins}`);
                        value = `${hours}:${mins}`;
                        e.target.value = value;
                    }
                    if (value != this.lastValue) {
                        this.lastValue = value;
                        if (!value) value = null;
                        if (onChange) onChange(value);
                    }
                }}
                {...props}
            />
        );
    }

}

export default TimeInput;