import React from 'react';
import { Link } from 'react-router-dom';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class ListReports extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            reports: []
        };
    }

    componentDidMount() {
        this.props.fns.setTitle('Reports');
        this.load();
    }

    async load() {
        this.setState({ isLoading: true });
        const reports = await API.call('report/list');
        this.setState({
            isLoading: false,
            reports
        });
    }

    //----------------------------------------------------------------------

    render() {
        const {
            isLoading,
            reports
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <section>

                {reports.map(r =>
                    <Link key={r.id} className="btn btn-primary me-3" to={`/report/${r.id}`}>
                        {r.isHeadOfficeOnly && <>
                            <span className="fa fa-lock" title="Head Office only" />{' '}
                        </>}
                        {r.name}
                    </Link>
                )}

            </section>

        </>);
    }
    
}

export default ListReports;