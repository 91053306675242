import React from 'react';
import Modal from 'react-bootstrap/Modal';

import FormHelper from 'helpers/FormHelper';
import API from 'API';

import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditCowlServiceModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };

        this.form = new FormHelper({
            fields: {
                cowlProductID: {
                    label: 'Cowl type',
                    type: 'single-select',
                    blankText: '(Other / decide later)',
                    getOptions: () => this.state.cowlProducts.map(p => ({
                        value: p.id,
                        text: p.name
                    }))
                },
                otherCowlName: {
                    type: 'text',
                    placeholder: 'Cowl type...'
                },
                cowlQuantity: {
                    label: 'Quantity',
                    type: 'number'
                }
            },
            getValue: (fieldName) => this.state.service[fieldName],
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                service: { ...opt.service },
                ...opt
            }, () => {
                this.load();
            });
        });
    }

    async load() {
        this.setState({ isLoading: true });
        const cowlProducts = await API.call('product/list', { category: 'Cowl' });
        this.setState({
            isLoading: false,
            cowlProducts
        });
    }

    saveAndClose() {
        this.resolve(this.state.service);
        this.close(); 
    }

    close() {
        this.setState({ isOpen: false });
    }

    updateFields(fields) {
        const service = { ...this.state.service };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            service[fieldName] = value;
        }
        this.setState({ service });
    }

    //--------------------------------------------------------------------------

    render() {
        const { 
            isOpen,
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="edit-cowl-service-modal" backdrop="static" keyboard={false}>
                {this.renderInner()}
            </Modal>
        );
    }

    renderInner() {
        const { isLoading, service } = this.state;
        
        if (isLoading) {
            return (
                <Modal.Body>
                    <Loader />
                </Modal.Body>
            );
        }

        return (
            <form onSubmit={e => {
                e.preventDefault();
                this.saveAndClose();
            }}>
                <Modal.Header>
                    <Modal.Title>Cowl</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {this.form.render([
                        'cowlProductID',
                        (!service.cowlProductID && 'otherCowlName'),
                        'cowlQuantity'
                    ])}

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                        Close
                    </button>
                    <button type="submit" className="btn btn-primary ms-auto">
                        Save
                    </button>
                </Modal.Footer>
            </form>
        );
    }
}

export default EditCowlServiceModal;