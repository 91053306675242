import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import TextHelpers from 'helpers/TextHelpers';
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import SuperTable from 'components/common/SuperTable';

//-----------------------------------------------------------------

class PriceCalcModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            
        };

        this.form = new FormHelper({
            fields: {
                //extraCharges: {
                //    label: 'Extra charges',
                //    type: 'currency'
                //},
                price: {
                    label: 'Price',
                    type: 'currency',
                    isReadOnly: true
                },
                updatePropertyPriceScheme: {
                    boxLabel: 'Update property price scheme to match this one',
                    type: 'checkbox'
                }
            },
            getValue: (fieldName) => this.state[fieldName],
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                canUsePrice: opt.canUsePrice,
                priceSchemeID: opt.priceSchemeID,
                oldPriceSchemeID: opt.priceSchemeID,
                services: opt.services || {},
                //extraCharges: '',
                updatePropertyPriceScheme: true
            }, () => {
                this.load();
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    updateFields(fields) {
        const newState = {};
        let loadServiceTypes = false, recalcPrice = false;
        for (let fieldName in fields) {
            const value = fields[fieldName];
            newState[fieldName] = value;

            switch (fieldName) {
                case 'priceSchemeID':
                    loadServiceTypes = true;
                    recalcPrice = true;
                    if (!this.state.serviceTypes) {
                        newState.isLoadingServiceTypes = true;
                    }
                    break;
                case 'services':
                case 'extraCharges':
                    recalcPrice = true;
                    break;
            }
        }

        this.setState(newState, () => {
            if (loadServiceTypes) {
                this.loadServiceTypes();
            }
            if (recalcPrice) {
                this.recalcPrice();
            }
        });
    }

    async load() {
        const priceSchemes = await API.call('price-scheme/list');

        // Select first price scheme if we clicked from the quotation menu rather than the appt editor
        let { priceSchemeID } = this.state;
        if (!priceSchemeID && priceSchemes.length > 0 && !this.state.canUsePrice) {
            priceSchemeID = priceSchemes[0].id;
        }

        // Update
        this.setState({
            priceSchemes,
            priceSchemeID,
            isLoading: false,
            isLoadingServiceTypes: true 
        }, () => {
            this.loadServiceTypes();
        });
    }

    async loadServiceTypes() {
        const { priceSchemeID } = this.state;
        //let { serviceTypes } = this.state;
        //this.setState({
        //    isLoadingServiceTypes: !serviceTypes
        //});
        let serviceTypes;
        if (priceSchemeID) {
            serviceTypes = await API.call('service-type/list', { priceSchemeID });
        }
        this.setState({
            serviceTypes,
            isLoadingServiceTypes: false
        }, () => {
            this.recalcPrice();
        });
    }

    async usePrice() {
        const { price, updatePropertyPriceScheme, priceSchemeID } = this.state;
        this.close();
        this.resolve({
            price,
            priceSchemeID: (updatePropertyPriceScheme ? priceSchemeID : null)
        });
    }
    
    updateService(serviceTypeID, value) {
        const services = { ...this.state.services };
        services[serviceTypeID] = value;
        this.updateFields({ services });
    }

    recalcPrice() {
        const { serviceTypes, services, extraCharges } = this.state;
        let price = (Number(extraCharges) || 0);
        if (serviceTypes) {
            serviceTypes.map(serviceType => {
                let num = parseInt(services[serviceType.id] || 0);
                if (num > 0) {
                    price += (serviceType.firstPrice || 0);
                    num--;
                }
                price += (serviceType.secondPlusPrice || 0) * num;
            });
        }
        this.setState({ price });
    }

    render() {
        const {
            isOpen,
            isLoading,
            canUsePrice,
            priceSchemes,
            priceSchemeID
        } = this.state;

        if (!isOpen) {
            return null;
        }
        const priceScheme = (priceSchemes ? priceSchemes.find(ps => ps.id == priceSchemeID) : null);

        return (
            <Modal show onHide={() => this.close()} className="price-calc-modal" backdrop="static" keyboard={false}>
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    <form onSubmit={e => { this.usePrice(); e.preventDefault() }}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Quotation
                                {priceScheme && <> - {priceScheme.name}</>}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            {this.renderInner()}

                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                                Close
                            </button>
                            {canUsePrice &&
                                <button type="submit" className="btn btn-primary ms-auto">
                                    Use price
                                </button>
                            }
                        </Modal.Footer>
                    </form>
                }
            </Modal>
        );
    }

    renderInner() {
        const { priceSchemes, priceSchemeID, oldPriceSchemeID, canUsePrice } = this.state;

        return (<>

            <div className="row">

                <div className="col-md-7">

                    <SuperTable
                        className="price-schemes-table table table-bordered clickable mb-3"
                        rows={priceSchemes}
                        keyAccessor={priceScheme => priceScheme.id}
                        rowPropsAccessor={priceScheme => {
                            if (priceScheme.id == priceSchemeID) {
                                return { className: 'row-selected' };
                            }
                        }}
                        cols={{
                            name: {
                                label: 'Name',
                                getValue: (colInfo, priceScheme, formattedValue, index) => <>
                                    {priceScheme.id == oldPriceSchemeID && 
                                        <span className="fa fa-check me-2" title="Current price scheme for this property" />
                                    }
                                    {priceScheme.name}
                                </>
                            },
                            description: { label: 'Description' },
                            firstPrice: { label: '1st Price', type: 'currency' },
                            secondPlusPrice: { label: '2nd+ Price', type: 'currency' }
                        }}
                        onClick={(priceScheme, e) => this.updateFields({ priceSchemeID: priceScheme.id }) }
                        emptyText="No price schemes to show"
                    />

                    {canUsePrice && priceSchemeID != oldPriceSchemeID &&
                        this.form.render(['updatePropertyPriceScheme'])
                    }

                </div>

                <div className="col-md-5">

                    <fieldset>

                        {this.renderServicesTable()}

                        {this.form.render('price')}

                        {/*
                        <div className="row">

                            <div className="col-md-6">

                                {this.form.render('extraCharges')}

                            </div>

                            <div className="col-md-6">


                            </div>

                        </div>
                        */}

                    </fieldset>

                </div>

            </div>

        </>);
    }

    renderServicesTable() {
        const {
            priceSchemeID,
            services,
            isLoadingServiceTypes,
            serviceTypes
        } = this.state;

        if (!priceSchemeID) {
            return null;
        }
        if (isLoadingServiceTypes) {
            return <Loader />
        }

        return (
            <table className="table table-bordered price-calc-service-types-table">
                <thead>
                    <tr>
                        <th className="service-type-col">Service</th>
                        <th className="num-col">#</th>
                        <th className="first-price-col">1st</th>
                        <th className="second-plus-price-col">2nd+</th>
                    </tr>
                </thead>
                <tbody>
                    {serviceTypes.map(st =>
                        <tr key={st.id}>
                            <td className="service-type-col">
                                {st.name}
                            </td>
                            <td className="num-col">
                                <input
                                    type="number"
                                    className="form-control"
                                    value={services[st.id] || ''}
                                    onChange={e => this.updateService(st.id, e.target.value)}
                                />
                            </td>
                            <td className="first-price-col">
                                {!!st.firstPrice && TextHelpers.formatCurrency(st.firstPrice)}
                            </td>
                            <td className="second-plus-price-col">
                                {!!st.secondPlusPrice && TextHelpers.formatCurrency(st.secondPlusPrice)}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}

export default PriceCalcModal;