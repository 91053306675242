import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import API from 'API';
import FormHelper from 'helpers/FormHelper';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditPaymentModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.form = new FormHelper({
            fields: {
                date: {
                    label: 'Date',
                    type: 'date'
                },
                amount: {
                    label: 'Amount',
                    type: 'currency'
                },
                paymentMethodID: {
                    label: 'Payment Method',
                    type: 'single-select',
                    blankText: 'Unknown / not specified',
                    getOptions: () => this.state.paymentMethods.map(pm => ({
                        value: pm.id,
                        text: pm.name
                    }))
                },
                isDeposit: {
                    boxLabel: 'Deposit?',
                    type: 'checkbox'
                },
                description: {
                    label: 'Description',
                    type: 'expanding-text'
                }
            },
            getValue: (fieldName) => this.state.payment[fieldName],
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                id: null,
                ...opt
            }, () => {
                this.load();
            });
        });
    }

    async load() {
        const payment = await API.call('payment/get/' + this.state.id);
        const paymentMethods = await API.call('payment-method/list');
        this.setState({
            payment,
            paymentMethods,
            isLoading: false
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    updateFields(newValues) {
        const payment = { ...this.state.payment };
        for (let fieldName in newValues) {
            const value = newValues[fieldName];
            payment[fieldName] = value;
        }
        this.setState({ payment });
    }

    async saveAndClose() {
        this.setState({ isLoading: true });
        await API.call('payment/save', this.state.payment);
        this.setState({ isOpen: false });
        this.resolve();
    }

    //--------------------------------------------------------------------------

    render() {
        const { 
            isOpen,
            isLoading
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="edit-payment-modal" backdrop="static" keyboard={false}>
                <form onSubmit={e => { e.preventDefault(); e.stopPropagation(); this.saveAndClose() }}>
                    <Modal.Body>

                        {this.renderInner()}

                    </Modal.Body>
                    {!isLoading &&
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary ms-auto">
                                Save
                            </button>
                        </Modal.Footer>
                    }
                </form>
            </Modal>
        );
    }

    renderInner() {
        const {
            isLoading,
            payment
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            {this.form.render([
                'date',
                'amount',
                'paymentMethodID',
                'isDeposit',
                'description'
            ])}

        </>);
    }
}

export default EditPaymentModal;