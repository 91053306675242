import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Components
import EditAppt from 'components/appt/EditAppt';

//-----------------------------------------------------------------

class EditApptModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                id: null,
                ...opt
            });
        });
    }

    close(reload) {
        if (reload) {
            this.resolve();
        }
        this.setState({ isOpen: false });
    }

    //--------------------------------------------------------------------------

    render() {
        const { 
            isOpen,
            id,
            controlPanelContent
        } = this.state;
        const { history } = this.props;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="edit-appt-modal" backdrop="static" keyboard={false}>
                <Modal.Header>
                    {controlPanelContent}
                </Modal.Header>
                <Modal.Body>

                    <EditAppt
                        {...this.props}
                        id={id}
                        history={history}
                        onUpdateControlPanel={controlPanelContent => this.setState({ controlPanelContent })}
                        onClose={(reload) => this.close(reload)}
                    />

                </Modal.Body>
                <Modal.Footer>
                    {controlPanelContent}
                </Modal.Footer>
            </Modal>
        );
    }

}

export default EditApptModal;