import React from 'react';
import moment from 'moment';

// Services & Helpers
import API from 'API';
import FormHelper from 'helpers/FormHelper';
import TextHelpers from 'helpers/TextHelpers';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

// Components
import Loader from 'components/common/Loader';
import EditAddress from 'components/common/EditAddress';
import EditChimneys from 'components/customer/EditChimneys';

//-----------------------------------------------------------------

class EditProperty extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };

        this.form = new FormHelper({
            fields: {
                priceSchemeID: {
                    label: 'Price Scheme',
                    type: 'single-select',
                    blankText: '(Select)',
                    getOptions: () => this.state.priceSchemes.map(ps => ({
                        value: ps.id,
                        text: ps.name   
                    }))
                },
                directions: {
                    label: 'Directions',
                    type: 'expanding-text',
                    rows: 1
                },
                access: {
                    label: 'Access Details',
                    type: 'expanding-text',
                    rows: 1
                },
                notes: {
                    label: 'Property Notes',
                    type: 'expanding-text',
                    rows: 1
                },
                isPORequired: {
                    boxLabel: 'PO Required',
                    type: 'checkbox'
                },
                isOccupied: {
                    label: 'Tenancy',
                    boxLabel: 'Property is occupied',
                    type: 'checkbox'
                },
                whatThreeWords: {
                    label: 'What.Three.Words',
                    type: 'text'
                },
                tenantName: {
                    label: 'Tenant name'
                },
                tenantEmail: {
                    label: 'Tenant email',
                    type: 'email'
                },
                tenantLandline: {
                    label: 'Tenant tel',
                    type: 'tel'
                },
                tenantMobile: {
                    label: 'Tenant mobile',
                    type: 'tel'
                },
                sendEmailsToTenant: {
                    boxLabel: 'Send emails to tenant as well as customer',
                    type: 'checkbox'
                }
            },
            getValue: (fieldName) => this.props.property[fieldName],
            setValue: (fieldName, value) => this.props.updateFields({ [fieldName]: value })
        });
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const priceSchemes = await API.call('price-scheme/list');
        this.setState({
            priceSchemes,
            isLoading: false
        });
    }

    updateAddressFields(fields, callback) {
        const address = { ...this.props.property.address };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            address[fieldName] = value;
        }
        this.props.updateFields({ address }, callback);
    }

    updateChimneyFields(chimney, fields) {
        const chimneys = [...this.props.property.chimneys || []];
        let index; 
        if (chimney.isNew) {
            chimneys.push({
                guid: TextHelpers.getRandomGUID(),
                floor: 'Ground',
                location: 'Living Room',
                isInUse: true
            });
            index = chimneys.length - 1;
        } else {
            index = chimneys.findIndex(c => c.guid == chimney.guid);
        }
        chimney = { ...chimneys[index] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            chimney[fieldName] = value;
        }
        chimneys[index] = chimney;
        this.props.updateFields({ chimneys });
    }

    //getLatestCertificate(isInline) {
    //    Print.url('/api/appt/get-certificate-pdf/' + this.state.property.latestApptID, {
    //        isInline
    //    });
    //}

    checkMissingHouseNumber(line1) {
        if (line1 && !(line1 || '').match(/([0-9]+[^ ]*) /)) {
            const { directions } = this.props.property;
            if (!directions) {
                alert('If a house number is not provided, you must provide directions');
            }
        }
    }

    //------------------------------------------------------------------------------------------------------

    render() {
        const { isLoading } = this.state;
        const { property, showCompanyFields } = this.props;

        if (isLoading) {
            return (<Loader />);
        }
        let hasHouseNumber = true;

        return (<>

            <fieldset>

                <EditAddress
                    {...this.props}
                    checkTerritory
                    address={property.address || {}}
                    updateFields={(fields, callback) => this.updateAddressFields(fields, callback)}
                    checkMissingHouseNumber={(line1) => this.checkMissingHouseNumber(line1)}
                    onSwitch={this.props.onSwitch}
                />

                {!hasHouseNumber &&
                    <div className="alert alert-danger">
                        If there is no house number provided, please provide directions!
                    </div>
                }
            
                <div className="row">

                    <div className="col-md-6">

                        {this.form.render('priceSchemeID')}

                    </div>

                    <div className="col-md-6">

                        {this.form.render('whatThreeWords')}

                    </div>

                </div>

                <div className="row">

                    <div className="col-md-4">

                        {this.form.render('notes')}

                    </div>

                    <div className="col-md-4">
                    
                        {this.form.render('directions')}
                    
                    </div>

                    <div className="col-md-4">

                        {this.form.render('access')}

                    </div>

                </div>

                {showCompanyFields && <>
                    {this.form.render([
                        'isPORequired',
                        'isOccupied'
                    ])}
                    {property.isOccupied && <>

                        <div className="row">

                            <div className="col-md-6">

                                {this.form.render('tenantName')}

                            </div>

                            <div className="col-md-6">

                                {this.form.render('tenantEmail')}

                            </div>

                        </div>

                        <div className="row">

                            <div className="col-md-6">

                                {this.form.render('tenantLandline')}

                            </div>

                            <div className="col-md-6">

                                {this.form.render('tenantMobile')}

                            </div>

                        </div>

                        {this.form.render('sendEmailsToTenant')}

                    </>}
                
                </>}

            </fieldset>

            <EditChimneys
                className="cust-chimneys-table"
                chimneys={property.chimneys || []}
                updateFields={(chimney, fields) => this.updateChimneyFields(chimney, fields)}
                onDedupe={() => this.props.onReload()}
            />
        </>);
    }


}

export default EditProperty;
