import React from 'react';
import { NumericFormat } from 'react-number-format';

class CurrencyInput extends React.Component {

    render() {
        return (
            <NumericFormat
                prefix="£"
                thousandSeparator=","
                decimalSeparator={'.'}
                {...this.props}
            />
        );
    }

}

export default CurrencyInput;