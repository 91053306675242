import React from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

import API from 'API';
import TextHelpers from 'helpers/TextHelpers';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';
import SearchBox from 'components/common/SearchBox';

//-----------------------------------------------------------------

class MovePropertyModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = { };
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                property: null,
                propertyID: opt.id,
                customers: [],
                query: null
            }, () => {
                this.load();
            });
        });
    }

    async load() {
        const property = await API.call('property/get/' + this.state.propertyID);
        this.setState({
            property,
            isLoading: false
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async search(query) {
        this.setState({ query });
        const nonce = `${Math.random()}`;
        const response = await API.call('search/customer-property', {
            query,
            nonce,
            ignoreCustomerID: this.state.property.customerID
        });
        if (response.nonce == nonce) {
            this.setState({
                customers: response.results
            });
        }
    }

    async selectOtherCustomer(c) {
        const confirm = window.confirm('Move property to this customer?');
        if (confirm) {
            this.setState({ isLoading: true });
            await API.call('property/move', {
                propertyID: this.state.property.id,
                customerID: c.id
            });
            this.close();
            this.resolve();
        }
    }

    //----------------------------------------------------------------------------------------------------

    render() {
        const { isOpen, isLoading, property } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="move-property-modal" backdrop="static">
                <Modal.Header>
                    {property &&
                        <Modal.Title>Move {property.name}</Modal.Title>
                    }
                    {/*<button type="button" className="btn btn-secondary ms-auto" onClick={() => this.close()}>
                        Close
                    </button>*/}
                </Modal.Header>
                <Modal.Body>
                        
                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    {isLoading ? <Loader /> : <>
                        <button type="button" className="btn btn-secondary me-auto" onClick={() => this.close()}>
                            Cancel
                        </button>
                    </>}
                </Modal.Footer>
            </Modal>
        );
    }

    renderInner() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <SearchBox
                className="form-control"
                placeholder="Search..."
                minLength={2}
                autoFocus={true}
                searchDelay={250}
                search={(query) => this.search(query)}
                clear={() => this.setState({ customers: [] })}
            />
            {this.renderSearchTable()}

        </>);
    }

    renderSearchTable() {
        const {
            isLoading,
            customers,
            query
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        // Flatten list
        let customersFlattened;
        if (customers && customers.length > 0) {
            customersFlattened = [];
            customers.forEach(c => {
                if (!ReferenceHelpers.isIndividual(c.customerType)) {
                    customersFlattened.push({
                        ...c
                    });
                }
                c.properties.forEach(p => {
                    const customerAndProperty = {
                        ...c,
                        isProperty: true,
                        property: { ...p }
                    };
                    customersFlattened.push(customerAndProperty);
                });
            });
        }

        return (<>
            <SuperTable
                className="customers-table table clickable table-bordered clickable mb-0"
                rows={customersFlattened || []}
                keyAccessor={c => `${c.id}-${c.property ? c.property.id : 'company'}`}
                cols={{
                    name: {
                        label: 'Name',
                        getValue: (colInfo, customer) => {
                            const lines = [
                                customer.name,
                                customer.companyName ? 'Company: ' + customer.companyName : ''
                            ];
                            return lines.filter(l => !!l).map((l, index) =>
                                <div key={index}>{TextHelpers.formatHighlightedText(l)}</div>
                            );
                        }
                    },
                    address: {
                        label: 'Property',
                        getValue: (colInfo, c) => {
                            if (c.property) {
                                return TextHelpers.formatHighlightedText(c.property.address);
                            } else {
                                return 'Company';
                            }
                        }
                    },
                    lastApptDate: {
                        label: 'Last Appt',
                        type: 'date',
                        getValue: (colInfo, c) => {
                            if (c.property && c.property.lastApptDate) {
                                return moment(c.property.lastApptDate).format('DD/MM/YYYY');
                            }
                        }
                    },
                    nextApptDate: {
                        label: 'Next Appt',
                        type: 'date',
                        getValue: (colInfo, c) => (c.property && c.property.nextApptDate ? moment(c.property.nextApptDate).format('DD/MM/YYYY') : '')
                    }
                }}
                onClick={(c, e) => this.selectOtherCustomer(c)}
                emptyText={query && customersFlattened && customersFlattened.length == 0 ?
                    'No matching customers or properties' : 'Please enter a search query'}
            />
        </>);
    }
    
}

export default MovePropertyModal;