import React from 'react';

// Services
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';

// Components
import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';
import EditAccountModal from 'components/head-office/EditAccountModal';

//-----------------------------------------------------------------

class ListAccounts extends React.Component {

    constructor(props) {
        super(props);

        this.editAccountModalRef = React.createRef();

        this.state = {
            accounts: []
        };
    }

    componentDidMount() {
        this.props.fns.setTitle('Accounts');
        this.load();
    }

    async load() {
        this.setState({ isLoading: true });
        
        // Load and update
        const accounts = await API.call('head-office/list-accounts');
        this.setState({
            isLoading: false,
            accounts
        });
    }

    async open(id) {
        await this.editAccountModalRef.current.open({
            id
        });
        this.load();
    }

    async add() {
        await this.editAccountModalRef.current.open({
        });
        this.load();
    }

    //----------------------------------------------------------------------

    render() {
        return (<>
            <section className="control-panel">

                <div className="control-panel-component ms-auto">
                    <button type="button" className="btn btn-secondary" onClick={() => this.add()}>
                        New account
                    </button>
                </div>

            </section>

            <section>

                {this.renderTable()}

            </section>

            <EditAccountModal
                ref={this.editAccountModalRef}
                {...this.props}
            />

        </>);
    }

    renderTable() {
        const {
            isLoading,
            accounts
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        
        const cols = {
            territoryNumber: {
                label: 'Number'
            },
            name: {
                label: 'Name'
            },
            type: {
                label: 'Type',
                getValue: (colInfo, account) => {
                    if (account.isHeadOffice) return 'Head office';
                    else if (account.isBookingAgent) return 'Booking agent';
                    else return 'Franchisee';
                }
            },
            franchiseeUser: {
                label: 'Franchisee',
                getValue: (colInfo, account) => {
                    if (account.franchiseeUser) {
                        return `${account.franchiseeUser.firstName} ${account.franchiseeUser.lastName}`;
                    }
                }
            },
            registeredAddress: {
                label: 'Registered Address',
                getValue: (colInfo, account) => {
                    if (account.registeredAddress) {
                        return TextHelpers.formatAddress(account.registeredAddress);
                    }
                    return '';
                }
            }
        };

        return (
            <SuperTable
                className="accounts-table table table-bordered clickable mb-0"
                rows={accounts}
                keyAccessor={account => account.id}
                cols={cols}
                onClick={(account, e) => this.open(account.id)}
            />
        );
    }
}

export default ListAccounts;