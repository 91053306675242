import React from 'react';
import Modal from 'react-bootstrap/Modal';
import queryString from 'query-string';

// Services
import API from 'API';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class PreviewEmailModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                id: null,
                ...opt
            }, () => {
                this.load();
            });
        });
    }

    close() {
        this.resolve(false);
        this.setState({ isOpen: false });
    }

    async load() {
        const { subject, attachments } = await API.call('email/preview/meta', this.state.email);
        this.setState({
            subject,
            attachments,
            isLoading: false
        });
    }

    getBodyPreviewURL() {
        const url = '/api/email/preview/body?' + queryString.stringify(this.state.email);
        return url;
    }

    async send() {
        this.resolve(true);
        this.setState({ isOpen: false });
    }

    //--------------------------------------------------------------------------

    render() {
        const { 
            isOpen,
            canSend
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="send-email-modal" backdrop="static" keyboard={false}>
                <Modal.Body>
                    
                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary me-auto" onClick={() => this.close()}>
                        {canSend ? 'Skip' : 'Close'}
                    </button>
                    {canSend &&
                        <button type="button" className="btn btn-primary ms-auto" onClick={() => this.send()}>
                            Send
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        );
    }

    renderInner() {
        const { isLoading, subject, attachments } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <div className="row">

                <div className="col-md-12">

                    <div className="form-group">
                        <label className="form-label">Subject</label>
                        <input type="text" className="form-control" disabled value={subject} />
                    </div>

                    {attachments.length > 0 &&
                        <div className="form-group attachments">
                            <label className="form-label">Attachments</label>
                            <p className="form-control-plaintext">
                                
                                {attachments.map((attachment, index) =>
                                    <a key={index} className="btn btn-tertiary" href={`/api/email/preview/attachment/${attachment.fileName}?${queryString.stringify(this.state.email)}`} target="_blank">
                                        {attachment.fileName}
                                    </a>
                                )}

                            </p>
                        </div>
                    }

                    <div className="form-group mb-0">
                        <label className="form-label">Body</label>
                        <iframe className="email-preview" src={this.getBodyPreviewURL()} />
                    </div>

                </div>
                
            </div>

        </>);
    }
}

export default PreviewEmailModal;