// Libs
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import DatePicker from 'components/common/DatePicker';
import ExpandingTextArea from 'react-expanding-textarea';

//-------------------------------------------------------------------------------------------------------------------

export default class FormHelper {

    constructor(opt) {
        opt = opt || {};
        this.fields = opt.fields || {};
        this.getValue = opt.getValue || ((fieldName, fieldInfo) => console.log('Please specify getValue'));
        this.setValue = opt.setValue || ((fieldName, value, fieldInfo) => console.log('Please specify setValue'));
        this.className = opt.className;
        this.onBlur = opt.onBlur;
        this.onValidate = opt.onValidate;
        this.renderWrapper = opt.renderWrapper || ((fieldName, fieldInfo, component) =>
            <div className="form-group">
                {!!fieldInfo.label &&
                    <label className="form-label">
                        {fieldInfo.label}
                        {fieldInfo.getLabelExtras && fieldInfo.getLabelExtras()}
                    </label>
                }
                {component}
                {fieldInfo.getExtras && fieldInfo.getExtras()}
            </div>);

        this.validation = {
            isValid: false,
            errors: {}
        };
    }

    //renderFormGroup(fieldName, validation) {
    //    if (!fieldName) return;
    //    let fieldInfo = this.fields[fieldName];
    //    if (!fieldInfo) {
    //        fieldInfo = {
    //            label: fieldName
    //        };
    //    }

    //    const formGroupClasses = ['form-group'];
    //    if (fieldInfo.validation) {
    //        if (fieldInfo.validation.isRequired) {
    //            formGroupClasses.push('field-required');
    //        }
    //        if (validation && validation.errors[fieldName] && validation.errors[fieldName].length > 0) {
    //            formGroupClasses.push('field-error');
    //        }
    //    }

    //    let label = fieldInfo.label;
    //    if (label && DataHelpers.isFunction(label)) {
    //        label = '' + label(fieldInfo);
    //    }

    //    return (
    //        <div className={formGroupClasses.join(' ')}>
    //            {label &&
    //                <label>{label}</label>
    //            }
    //            {this.renderField(fieldName, validation)}
    //        </div>
    //    );
    //}

    render(fieldNames) {
        if (typeof (fieldNames) == 'string' || fieldNames instanceof String) {
            fieldNames = [fieldNames];
        }
        return fieldNames.map(fieldName => {
            // Get field info
            if (!fieldName) return null;
            let fieldInfo = this.fields[fieldName];
            if (!fieldInfo) {
                fieldInfo = {
                    type: 'text',
                    label: fieldName
                };
            }
            fieldInfo.type = fieldInfo.type || 'text';

            // Render component
            const component = this.renderFieldComponent(fieldName, fieldInfo);
            return (
                <React.Fragment key={fieldName}>
                    {this.renderWrapper(fieldName, fieldInfo, component)}
                </React.Fragment>
            );
        });
    }

    renderFieldComponent(fieldName, fieldInfo) {
        let value;
        if (fieldInfo.getValue) {
            value = fieldInfo.getValue();
        } else {
            value = this.getValue(fieldInfo.field || fieldName, fieldInfo) || '';
        }
        if (fieldInfo.lastValue === null || typeof(fieldInfo.lastValue) === 'undefined') {
            fieldInfo.lastValue = value;
        }

        let extraProps = {};
        if (fieldInfo.getProps) {
            extraProps = { ...fieldInfo.getProps() };
        }

        let boxLabel = fieldInfo.boxLabel;
        if (boxLabel instanceof Function) {
            boxLabel = boxLabel();
        }

        let isReadOnly = fieldInfo.isReadOnly;
        if (isReadOnly instanceof Function) {
            isReadOnly = isReadOnly();
        }

        switch (fieldInfo.type) {
            case 'text':
            case 'password':
            case 'number':
            case 'tel':
            case 'search':
            case 'email':
            case 'color':
                return (
                    <input
                        ref={fieldInfo.ref}
                        type={fieldInfo.type}
                        className={'form-control ' + (fieldInfo.className || '')}
                        value={value}
                        autoFocus={fieldInfo.autoFocus}
                        placeholder={fieldInfo.placeholder}
                        disabled={isReadOnly}
                        onChange={e => {
                            this.setValue(fieldName, e.target.value, fieldInfo);
                            this.validate(fieldName, fieldInfo, e.target.value);
                        }}
                        onFocus={e => {
                            fieldInfo.lastValue = e.target.value;
                        }}
                        onBlur={e => {
                            if (this.onBlur && e.target.value != fieldInfo.lastValue) {
                                fieldInfo.lastValue = e.target.value;
                                this.onBlur(fieldName, e.target.value, fieldInfo);
                            }
                        }}
                        {...fieldInfo.props}
                        {...extraProps}
                    />
                );
            case 'time':
                return (
                    <input
                        ref={fieldInfo.ref}
                        type="text"
                        className={'form-control ' + (fieldInfo.className || '')}
                        key={value}
                        defaultValue={value}
                        autoFocus={fieldInfo.autoFocus}
                        placeholder={fieldInfo.placeholder}
                        disabled={isReadOnly}
                        onFocus={e => {
                            fieldInfo.lastValue = e.target.value;
                        }}
                        onBlur={e => {
                            let value = e.target.value;
                            const regex = new RegExp(/([0-9]{1,2})([\.:,_\- ]?)([0-9]{0,2})/g);
                            const match = regex.exec(value);
                            if (match) {
                                let hours = parseInt(match[1]) || 0;
                                let mins = parseInt(match[3]) || 0;

                                // If they write, e.g. 620, make sure that ends up as 6:20 not 62:0
                                if (hours > 24) {
                                    mins = parseInt(`${hours % 10}${mins}`);
                                    hours = Math.floor(hours / 10);
                                }

                                hours = (hours < 10 ? `0${hours}` : `${hours}`);
                                mins = (mins < 10 ? `0${mins}` : `${mins}`);
                                value = `${hours}:${mins}`;
                                e.target.value = value;
                            }
                            if (value != fieldInfo.lastValue) {
                                fieldInfo.lastValue = value;
                                if (!value) value = null;
                                this.setValue(fieldName, value, fieldInfo);
                            }
                        }}
                        {...fieldInfo.props}
                        {...extraProps}
                    />
                );
            case 'checkbox':
                return (
                    <label className="checkbox-label">
                        <input
                            ref={fieldInfo.ref}
                            type="checkbox"
                            className={(fieldInfo.className || '')}
                            checked={!!value}
                            disabled={isReadOnly}
                            onChange={e => {
                                this.setValue(fieldName, e.target.checked, fieldInfo);
                                this.validate(fieldName, fieldInfo, e.target.checked);
                            }}
                            {...fieldInfo.props}
                            {...extraProps}
                        />
                        {boxLabel &&
                            <span>{boxLabel}</span>
                        }
                    </label>
                );

            case 'multiline-text':
                fieldInfo.rows = fieldInfo.rows || 4;
                return (
                    <textarea
                        ref={fieldInfo.ref}
                        className={'form-control ' + (fieldInfo.className || '')}
                        value={value}
                        rows={fieldInfo.rows}
                        autoFocus={fieldInfo.autoFocus}
                        placeholder={fieldInfo.placeholder}
                        disabled={isReadOnly}
                        onChange={e => {
                            this.setValue(fieldName, e.target.value, fieldInfo);
                            this.validate(fieldName, fieldInfo, e.target.value);
                        }}
                        onFocus={e => {
                            fieldInfo.lastValue = e.target.value;
                        }}
                        onBlur={e => {
                            if (this.onBlur && e.target.value != fieldInfo.lastValue) {
                                fieldInfo.lastValue = e.target.value;
                                this.onBlur(fieldName, e.target.value, fieldInfo);
                            }
                        }}
                        {...fieldInfo.props}
                        {...extraProps}
                    />
                );
            case 'expanding-text':
                fieldInfo.rows = fieldInfo.rows || 4;
                return (
                    <ExpandingTextArea
                        ref={fieldInfo.ref}
                        className={'form-control ' + (fieldInfo.className || '')}
                        value={value}
                        rows={fieldInfo.rows}
                        autoFocus={fieldInfo.autoFocus}
                        placeholder={fieldInfo.placeholder}
                        onChange={e => {
                            this.setValue(fieldName, e.target.value, fieldInfo);
                            this.validate(fieldName, fieldInfo, e.target.value);
                        }}
                        onFocus={e => {
                            fieldInfo.lastValue = e.target.value;
                        }}
                        onBlur={e => {
                            if (this.onBlur && e.target.value != fieldInfo.lastValue) {
                                fieldInfo.lastValue = e.target.value;
                                this.onBlur(fieldName, e.target.value, fieldInfo);
                            }
                        }}
                        {...fieldInfo.props}
                        {...extraProps}
                    />
                );
            case 'single-select': {
                fieldInfo.getOptions = fieldInfo.getOptions || (() => console.log('Please specify getOptions on field ' + fieldName));
                let options = fieldInfo.getOptions();
                if (fieldInfo.blankText) {
                    options = [{
                        value: '',
                        text: fieldInfo.blankText
                    }].concat(options);
                }

                return (
                    <select
                        ref={fieldInfo.ref}
                        className={'form-control ' + (fieldInfo.className || '')}
                        value={value}
                        disabled={isReadOnly}
                        onChange={e => {
                            this.setValue(fieldName, e.target.value, fieldInfo);
                            this.validate(fieldName, fieldInfo, e.target.value);
                        }}
                        {...fieldInfo.props}
                        {...extraProps}
                    >
                        {options && options.map(o =>
                            <option key={o.value || '__BLANK__'} value={o.value}>{o.text}</option>
                        )}
                    </select>
                );
            }

            case 'checkboxes':
            case 'radios': {
                fieldInfo.getOptions = fieldInfo.getOptions || (() => console.log('Please specify getOptions on field ' + fieldName));
                const options = fieldInfo.getOptions();

                return (
                    <div className={fieldInfo.wrapperClassName || ''}>

                        {options.map(o =>
                            <label key={o.value} className={`${fieldInfo.type == 'checkboxes' ? 'checkbox' : 'radio'}-label`}>
                                <input
                                    ref={fieldInfo.ref}
                                    type={fieldInfo.type == 'checkboxes' ? 'checkbox' : 'radio'}
                                    className={'form-control ' + (fieldInfo.className || '')}
                                    checked={!!value[`${o.value}`]}
                                    onChange={e => {
                                        value = { ...value };
                                        if (e.target.checked) {
                                            value[`${o.value}`] = true;
                                        } else {
                                            delete value[`${o.value}`];
                                        }
                                        this.setValue(fieldName, value, fieldInfo);
                                        this.validate(fieldName, fieldInfo, value);
                                    }}
                                    {...fieldInfo.props}
                                    {...extraProps}
                                />
                                {o.text &&
                                    <span>{o.text}</span>
                                }
                            </label>
                        )}
                    </div>
                );
            }

            case 'date':
            case 'date-text':
                if (value) {
                    value = moment(value).toDate();
                }
                return (
                    <DatePicker
                        ref={fieldInfo.ref}
                        className={'form-control ' + (fieldInfo.className || '')}
                        value={value}
                        placeholder={fieldInfo.placeholder}
                        disabled={isReadOnly}
                        onChange={value => {
                            if (fieldInfo.type == 'date-text') {
                                value = moment(value).format('YYYY-MM-DD');
                            }
                            this.setValue(fieldName, value, fieldInfo)
                            this.validate(fieldName, fieldInfo, value);
                        }}
                        {...fieldInfo.props}
                        {...extraProps}
                    />
                );
            case 'checkbox':
                return (
                    <input
                        ref={fieldInfo.ref}
                        type="checkbox"
                        className={this.className}
                        checked={!!value}
                        disabled={isReadOnly}
                        readOnly={isReadOnly}
                        onChange={e => {
                            this.setValue(fieldName, e.target.checked, fieldInfo);
                            this.validate(fieldName, fieldInfo);
                        }}
                        {...fieldInfo.props}
                        {...extraProps}
                    />
                );
            case 'currency':
                return (
                    <NumericFormat
                        ref={fieldInfo.ref}
                        className={'form-control ' + (fieldInfo.className || '')}
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        prefix="£"
                        value={value || ''}
                        disabled={isReadOnly}
                        onValueChange={(values) => {
                            const value = Number(values.value) || '';
                            this.setValue(fieldName, value);
                            this.validate(fieldName, fieldInfo, value);
                        }}
                        {...fieldInfo.props}
                        {...extraProps}
                    />
                );
        }
    }

    validate(fieldName, fieldInfo, value) {
        const validation = fieldInfo.validation;
        if (validation) {
            this.validation.errors[fieldName] = [];
            const fieldErrors = this.validation.errors[fieldName];
            if (validation.isRequired) {
                if (!value) {
                    fieldErrors.push(validation.requiredMessage || 'This field is required');
                }
            }
        }
        this.updateIsValid();
        if (this.onValidate) {
            this.onValidate(this.validation);
        }
    }

    updateIsValid() {
        this.validation.isValid = true;
        for (var fieldName in this.fields) {
            if (this.validation.errors[fieldName] && this.validation.errors[fieldName].length > 0) {
                this.validation.isValid = false;
                return;
            }
        }
    }

    getValidationSummary() {
        const errors = [];
        for (var fieldName in this.fields) {
            const validationErrors = this.validation.errors[fieldName];
            if (validationErrors) {
                validationErrors.forEach(e => {
                    errors.push({
                        fieldName: fieldName,
                        fieldLabel: this.fields[fieldName].label || fieldName,
                        error: e
                    })
                });
            }
        }
        return errors;
    }

    getValidationSummaryHTML() {
        const validationSummary = this.getValidationSummary();
        return ReactDOMServer.renderToString(<ul>
            {validationSummary.map(v =>
                <li>
                    <b>{v.fieldLabel}</b>: {v.error}
                </li>    
            )}
        </ul>);
    }
}
