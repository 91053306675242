import {useState, forwardRef, useImperativeHandle} from "react";
import Modal from "react-bootstrap/Modal";
import FormHelper from "../../helpers/FormHelper";
import ReferenceHelpers from "../../helpers/ReferenceHelpers";
import {Button} from "react-bootstrap";

const AddRebookModal = forwardRef(({onAddNewRebookSave}, ref) => {
    const [properties, setProperties] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [isOpen, setIsOpen] = useState(false);

    let form = new FormHelper({
        fields: {
            rebookMonths: {
                label: 'Month',
                type: 'checkboxes',
                wrapperClassName: 'rebook-months',
                getOptions: () => ReferenceHelpers.Months
            }
        },
        getValue: (fieldName) =>  selectedProperty[fieldName],
        setValue: (fieldName, value) => setSelectedProperty({...selectedProperty, rebookMonths : value })
    });
    
    useImperativeHandle(ref, () => {
        return {
            open(properties) {
                setProperties(properties);
                setSelectedProperty(properties[0]);
                setIsOpen(true);
            }
        }
    })
    
    const save = () => {
        setIsOpen(false);
        onAddNewRebookSave(selectedProperty, selectedYear);
    }
    
    const close = () => {
        setIsOpen(false);
    }
    
    if (isOpen && properties) {
        return (
            <>
                <Modal show onHide={() => this.close()} centered backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Add new rebook</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <>
                                <div>Select property:</div>
                                <select className="form-select" defaultValue={properties[0].id} name="Test" style={{ marginBottom: '10px' }} onChange={(e) => setSelectedProperty(properties.find(p => p.id == e.currentTarget.value)) }>
                                    {properties.map((property) => (
                                        <option key={property.id} value={property.id}>{property.name}</option>
                                    ))}
                                </select>
    
                                {selectedProperty != null && form.render([
                                    'rebookMonths'
                                ])}    
                                    
                                <div style={{ display: 'flex', alignItems: 'center'}}>
                                    <div style={{ marginRight: '10px' }}>Year</div>
                                    
                                    <select className="form-select" style={{ width: '100px'}} defaultValue={selectedYear} onChange={(e) => setSelectedYear(e.currentTarget.value)}>
                                        <option key={new Date().getFullYear()} value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                                        <option key={new Date().getFullYear()+1} value={new Date().getFullYear()+1}>{new Date().getFullYear()+1}</option>
                                        <option key={new Date().getFullYear()+2} value={new Date().getFullYear()+2}>{new Date().getFullYear()+2}</option>
                                        <option key={new Date().getFullYear()+3} value={new Date().getFullYear()+3}>{new Date().getFullYear()+3}</option>
                                    </select>
                                </div>    

                            </>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => close()}>Cancel</Button>
                        <Button variant="primary" onClick={() => save()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
    
    return null;
})

export default AddRebookModal;