// Libs
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Components
import App from 'App';

// JS 
import "bootstrap/dist/js/bootstrap.min.js";

// CSS
import 'bootstrap/dist/css/bootstrap.css';
import './scss/main.scss'

//-------------------------------------------------------------------------------------------------------------------

const rootElement = document.getElementById('root');

const getBaseURL = () => {
    let accountCode = window.location.pathname;
    if (accountCode.split('/').length >= 1) {
        accountCode = accountCode.split('/')[1];
    } else {
        accountCode = '';
    }
    return accountCode;
};

ReactDOM.render(
    <BrowserRouter basename={getBaseURL()}>
        <App />
    </BrowserRouter>,
  rootElement);

//registerServiceWorker();

