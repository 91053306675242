import React from 'react';
import Modal from 'react-bootstrap/Modal';

import Loader from 'components/common/Loader';

import CustomerCommList from 'components/customer/CustomerCommunication/CustomerCommList';
import ApptList from 'components/customer/ApptList';

//-----------------------------------------------------------------

class CustomerCommsModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                customerID: opt.customerID,
                propertyID: opt.propertyID
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    render() {
        const { 
            isOpen
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="customer-comms-modal" backdrop="static">
                <form onSubmit={e => { e.stopPropagation(); e.preventDefault(); this.saveAndClose() }}>
                    <Modal.Header>
                        <button type="button" className="btn btn-secondary ms-auto" onClick={() => this.close()}>
                            Close
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        
                        {this.renderInner()}

                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                            Close
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }

    renderInner() {
        const { history } = this.props;
        const { isLoading, customerID, propertyID } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (

            <div className="row">

                <div className="col-md-6">

                    <CustomerCommList
                        customerID={customerID}
                        propertyID={propertyID}
                    />

                </div>
                
                <div className="col-md-6">

                    <ApptList
                        history={history}
                        customerID={customerID}
                        propertyID={propertyID}
                        hideButtons
                        disableClick
                    />

                </div>

            </div>

        );
    }
}

export default CustomerCommsModal;