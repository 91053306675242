import React from 'react';

// Services & Helpers
import API from 'API';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

// Components
import Loader from 'components/common/Loader';
import SuperTable from 'components/common/SuperTable';
import CustomerCommunicationModal from 'components/customer/CustomerCommunication/CustomerCommunicationModal';

//-----------------------------------------------------------------

class CustomerCommList extends React.Component {

    constructor(props) {
        super(props);

        this.editCustomerCommModalRef = React.createRef();

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        this.setState({ isLoading: true });
        const { customerID, propertyID, customerRebooks } = this.props;
        const customerLogs = await API.call('customer-comm/list', {
            customerID,
            propertyID
        });
        this.setState({
            isLoading: false,
            customerLogs,
            customerRebooks
        });
    }

    async addCustomerComm() {
        const { customerID, propertyID, onAdd } = this.props;
        await this.editCustomerCommModalRef.current.open({
            customerID,
            propertyID,
            type: this.props.defaultType || 'Note'
        });
        if (onAdd) {
            onAdd();
        }
        await this.load();
    }

    async editCustomerComm(id) {
        await this.editCustomerCommModalRef.current.open({
            id
        });
        await this.load();
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {
        const { isLoading, customerLogs } = this.state;

        const customerCommResultsLookup = {};
        ReferenceHelpers.OldAndNewCustomerCommResults.forEach(ccr => customerCommResultsLookup[ccr.id] = ccr);
        const cols = {
            dateTime: {
                label: 'Date / Time',
                type: 'dateTime'
            },
            userNickname: {
                label: 'User',
                getValue: (colInfo, cc) => cc.user ? cc.user.nickname : ''
            },
            type: {
                label: 'Type'
            },
            description: {
                label: 'Description',
                getValue: (colInfo, cc) => {
                    if (cc.emailType) {
                        return cc.subject;
                    }
                    else if (cc.result) {
                        return (customerCommResultsLookup[cc.result] ? customerCommResultsLookup[cc.result].name : '')
                    } else {
                        return cc.description;
                    }
                }
            }//,
            //description: {
            //    label: 'Notes'
            //},
            //recipient: {
            //    label: 'Recipient'
            //},
            //subject: {
            //    label: 'Subject'
            //}
        };
        
        return (<>
            <section>

                <h2>
                    Contact / Activity Log

                    <button type="button" className="btn btn-tertiary ms-auto" onClick={() => this.addCustomerComm()}>
                        <span className="fa-solid fa-bullhorn" />{' '}
                        Add communication...
                    </button>

                </h2>

                {isLoading ? <Loader /> :
                    <div className="sticky-table-container">
                        <SuperTable
                            className="customer-logs-table table table-bordered clickable mb-0"
                            rows={customerLogs}
                            keyAccessor={cc => cc.id}
                            cols={cols}
                            onClick={cc => this.editCustomerComm(cc.id)}
                            emptyText="No communications logged for this customer"
                        />
                    </div>
                }

            </section>

            <CustomerCommunicationModal ref={this.editCustomerCommModalRef} />

        </>);
    }

}

export default CustomerCommList;