import React from 'react';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import SuperTable from 'components/common/SuperTable';
import EditCustomerSourceModal from 'components/setup/EditCustomerSourceModal';

//-----------------------------------------------------------------

class SetupCustomerSources extends React.Component {

    constructor(props) {
        super(props);

        this.editCustomerSourceModalRef = React.createRef();

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.props.fns.setTitle('Customer sources setup');
        this.load();
    }

    async load() {
        const customerSources = await API.call('customer-source/list');
        this.setState({
            isLoading: false,
            customerSources
        });
    }

    async open(id) {
        await this.editCustomerSourceModalRef.current.open({
            id
        });
        this.load();
    }

    async add() {
        await this.editCustomerSourceModalRef.current.open({
        });
        this.load();
    }
    
    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            customerSources
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <section className="control-panel sticky">

                <h1>Customer Sources</h1>

                <button type="button" className="ms-auto btn btn-primary" onClick={() => this.add()}>
                    Add Customer Source
                </button>

            </section>

            <section>

                <SuperTable
                    className="customer-sources-table table table-bordered clickable mb-0"
                    rows={customerSources}
                    keyAccessor={customerSource => customerSource.id}
                    cols={{
                        name: { label: 'Name' },
                        numCustomers: { label: '# Customers' }
                    }}
                    onClick={(customerSource, e) => this.open(customerSource.id)}
                    emptyText="No customer sources to show"
                />

            </section>

            <EditCustomerSourceModal
                ref={this.editCustomerSourceModalRef}
            />

        </>);
    }
    
}

export default SetupCustomerSources;