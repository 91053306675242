import React from 'react';
import moment from 'moment';

// Services & Helpers
import API from 'API';
import FormHelper from 'helpers/FormHelper';
import TextHelpers from 'helpers/TextHelpers';

// Components
import Loader from 'components/common/Loader';
import SuperTable from 'components/common/SuperTable';
import EditPaymentModal from 'components/appt/EditPaymentModal';
import EditApptModal from 'components/appt/EditApptModal';

//-----------------------------------------------------------------

class Banking extends React.Component {

    constructor(props) {
        super(props);

        this.editPaymentModalRef = React.createRef();
        this.editApptModalRef = React.createRef();

        this.state = {
            filters: {
                dateFrom: moment().startOf('isoWeek').format('YYYY-MM-DD'),
                dateTo: null,
                groupBy: 'Type,Sweep',
                sortBy: 'Customer'
            }
        };

        this.form = new FormHelper({
            fields: {
                dateFrom: {
                    label: 'Date (from)',
                    type: 'date'
                },
                dateTo: {
                    label: 'Date (to)',
                    type: 'date'
                },
                groupBy: {
                    label: 'Group by',
                    type: 'single-select',
                    getOptions: () => [
                        { value: 'Type', text: 'Type' }, 
                        { value: 'Type,Sweep', text: 'Type » Sweep' }, 
                        { value: 'Type,PaymentMethod', text: 'Type » Payment Method' }, 
                        { value: 'Sweep,ApptDate', text: 'Sweep » Appt Date' }, 
                        { value: 'Sweep,PaymentMethod', text: 'Sweep » Payment Method' }, 
                        { value: 'PaymentMethod', text: 'Payment Method' } 
                    ]
                },
                sortBy: {
                    label: 'Sort by',
                    type: 'single-select',
                    getOptions: () => [
                        { value: 'Customer', text: 'Customer' },
                        { value: 'Status', text: 'Status' },
                        { value: 'Property', text: 'Property' },
                        { value: 'ApptDateTime', text: 'Appt Date' },
                        { value: 'PaymentDate', text: 'Paid on' },
                        { value: 'PaymentMethodName', text: 'Payment Method' },
                        { value: 'AmountQuoted', text: 'Amount quoted' },
                        { value: 'AmountPaid', text: 'Amount paid' }
                    ]
                },
                sortDesc: {
                    label: 'Descending',
                    type: 'checkbox'
                }
            },
            getValue: (fieldName) => this.state.filters[fieldName],
            setValue: (fieldName, value) => this.updateFilters({ [fieldName]: value }),
            renderWrapper: (fieldName, fieldInfo, component) => (
                <div className="control-panel-component">
                    <div className="form-group">
                        {!!fieldInfo.label &&
                            <label className="form-label">
                                {fieldInfo.label}
                                {fieldInfo.getLabelExtras && fieldInfo.getLabelExtras()}
                            </label>
                        }
                        {component}
                    </div>
                </div>
            )
        });
    }

    componentDidMount() {
        this.load();
    }

    updateFilters(newValues) {
        const filters = { ...this.state.filters };
        for (let fieldName in newValues) {
            let value = newValues[fieldName];
            switch (fieldName) {
                case 'dateFrom':
                case 'dateTo':
                    value = moment(value).format('YYYY-MM-DD');
                    break;
            }
            filters[fieldName] = value;
        }
        this.setState({
            filters,
            isLoading: true
        }, () => {
            this.load();
        });
    }

    async load() {
        this.setState({ isLoading: true });
        try {
            const apptGroupings = await API.call('appt/list-for-banking-summary', this.state.filters);
            this.setState({
                apptGroupings,
                isLoading: false,
                error: null
            });
        } catch (e) {
            this.setState({
                apptGroupings: null,
                isLoading: false,
                error: e
            });
        }
    }

    async exportExcel() {
        this.setState({ isLoading: true });
        try {
            await API.call('appt/export-banking-summary-excel', {
                ...this.state.filters,
                isDownload: true
            });
            this.setState({
                isLoading: false,
                error: null
            });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: e
            });
        }
    }

    toggleSelect(row) {
        const apptGroupings = [...this.state.apptGroupings];
        if (row.isGrouping) {
            const isSelected = !row.isSelected;
            this.cascadeDown(row, (row) => {
                row.isSelected = isSelected;
            });
        } else {
            row.isSelected = !row.isSelected;
        }
        this.setState({ apptGroupings });
    }

    cascadeDown(row, fn) {
        fn(row);
        if (row.children) {
            row.children.forEach(c => this.cascadeDown(c, fn));
        }
    }

    async editPayment(id) {
        await this.editPaymentModalRef.current.open({
            id
        });
        this.load();
    }

    async addPayment(a) {
        if (this.state.isAddingPayment) return;
        this.setState({ isAddingPayment: true });
        const { id } = await API.call(`appt/mark-paid/${a.id}`);
        this.load();
        if (a.paymentType != 'SendInvoice') {
            this.editPayment(id);
        }
        this.setState({ isAddingPayment: false });
    }

    async openAppt(id) {
        await this.editApptModalRef.current.open({
            id
        });
        this.load();
    }

    //----------------------------------------------------------------------

    render() {
        return (<>

            <section className="control-panel">

                {this.form.render([
                    'dateFrom',
                    'dateTo',
                    //'dateBasedOn',
                    //'status',
                    //'paymentType',
                    'groupBy',
                    'sortBy',
                    'sortDesc',
                    //'includeInvoices'
                ])}

                <div className="buttons ms-auto">

                    <button type="button" className="btn btn-secondary me-3" onClick={() => window.print()}>
                        Print
                    </button>

                    <button type="button" className="btn btn-secondary me-3" onClick={() => this.exportExcel()}>
                        Export
                    </button>

                </div>

            </section>

            {this.renderInner()}

            <EditPaymentModal ref={this.editPaymentModalRef} />

        </>);
    }

    renderInner() {
        const {
            isLoading,
            error,
            apptGroupings,
            filters
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        const cols = {
            isSelected: {
                className: 'select-col',
                getValue: (colInfo, row) => (!row.isGrouping &&
                    <input
                        type="checkbox"
                        className="non-clickable"
                        checked={row.isSelected || false}
                        onClick={e => e.preventDefault()}
                        onChange={e => null}
                    />
                )
            },
            customer: {
                label: 'Customer',
                getValue: (colInfo, a) => a.isGrouping ? a.title :
                    (TextHelpers.formatName(a.appt.customer.title, a.appt.customer.firstName, a.appt.customer.lastName) || a.appt.customer.companyName)
            },
            property: {
                label: 'Property',
                sortable: true,
                getValue: (colInfo, a) => !a.isGrouping && a.appt.property ?
                    TextHelpers.formatAddress(a.appt.property.address) :
                    null
            },
            serviceTypeNames: {
                label: 'Type',
                getValue: (colInfo, a) => !a.isGrouping && a.appt ? a.appt.serviceTypeNames :
                    null
            },
            apptDateTime: {
                label: 'Appt date',
                sortable: true,
                getValue: (colInfo, a) => !a.isGrouping && a.appt.date ?
                    moment(a.appt.date).format('DD/MM/YYYY') + ` at ${a.appt.time}` :
                    null
            },
            paymentDate: {
                label: 'Paid on',
                sortable: true,
                getValue: (colInfo, a) => !a.isGrouping && a.paymentDate ?
                    moment(a.paymentDate).format('DD/MM/YYYY') :
                    null
            },
            duration: {
                label: 'Duration',
                type: 'number',
                getValue: (colInfo, a) => !a.isGrouping ? (a.appt.duration || '') :
                    null
            },
            paymentMethodName: {
                label: 'Method',
                getValue: (colInfo, a) => {
                    if (a.isGrouping) return '';
                    if (a.paymentType == 'SendInvoice') return 'Invoice';
                    else if (a.paymentType == 'PaymentToFollow') return 'Payment to follow';
                    else if (a.payments && a.payments.length > 0) return a.payments[a.payments.length - 1].methodName;
                    else return '';
                }
            },
            amountQuoted: {
                label: 'Quoted',
                type: 'currency'
            },
            amountPaid: {
                label: 'Paid',
                type: 'currency'
            },
            actions: {
                label: '',
                className: 'actions-col',
                getValue: (colInfo, a) => !a.isGrouping ? (<>
                    <button key={a.id} className="btn btn-sm btn-secondary" onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.openAppt(a.appt.id);
                    }}>
                        <span className="fa-solid fa-eye" />{' '}Edit appt
                    </button>

                    {a.payments.map(p =>
                        <button key={p.id} className="btn btn-sm btn-secondary" onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.editPayment(p.id);
                        }}>
                            <span className="fa-solid fa-pencil" /> {p.isDeposit ? 'Edit deposit' : 'Edit payment'}
                        </button>
                    )}
                    {!a.isPaid &&
                        <button className="btn btn-sm btn-secondary" title="Add payment" onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.addPayment(a);
                        }}> 
                            <span className="fa-solid fa-coins" /> Mark as paid
                        </button>
                    }
                </>) : null
            }
        };
        if (filters.groupBy.indexOf('PaymentMethod') != -1) {
            delete cols.methodName;
        }
        if (filters.groupBy.indexOf('Type') != -1) {
            delete cols.serviceTypeNames;
        }
        if (filters.groupBy.indexOf('ApptDate') != -1) {
            cols.dateTime = {
                label: 'Time',
                getValue: (colInfo, a) => !a.isGrouping && a.appt.date ? a.appt.time : null
            }
        }
        if (filters.status == 'Completed') {
            delete cols.status;
        }

        let rows;
        if (apptGroupings) {
            rows = [];
            const flatten = (row) => {
                rows.push(row);
                if (row.children) {
                    row.children.forEach(a => flatten(a));
                }
            };
            apptGroupings.forEach(g => flatten(g));
        }
        return (<>
            {!!error &&
                <section>
                    <div className="alert alert-danger mb-0">
                        {error}
                    </div>
                </section>
            }

            {rows && <>

                <section className="control-panel selection-summary">
                    {this.renderSelectionSummary()}
                </section>

                {/*
                <section className="selection-summary">
                    (Note: if a single appointment has multiple payments against it, only the first line will have a number in the Price Quoted field to avoid double-counting)
                </section>
                */}

                <section>
                    <SuperTable
                        className="banking-summary-table table table-bordered clickable mb-0"
                        rows={rows}
                        keyAccessor={row => row.id}
                        cols={cols}
                        onClick={(row, e) => this.toggleSelect(row)}
                        emptyText="No appointments"
                        cellPropsAccessor={(colInfo, row, index, colIndex) => {
                            if (!row) return null;
                            const style = {};
                            let className = (colInfo.col.className || '');
                            if (row.level && colIndex == 1) {
                                style.paddingLeft = parseInt(row.level) * 40 + 'px';
                            }
                            if (row.isGrouping) {
                                className += ` row-grouping-${row.level}`;
                            }
                            return { style, className };
                        }}
                    />
                </section>
            </>}

            <EditApptModal
                ref={this.editApptModalRef}
                {...this.props}
            />

        </>);
    }

    renderSelectionSummary() {
        const { apptGroupings } = this.state;
        let numSelected = 0;
        let amountQuoted = 0, amountPaid = 0;
        apptGroupings.forEach(ag => {
            this.cascadeDown(ag, (row) => {
                if (!row.isGrouping && row.isSelected) {
                    numSelected++;
                    amountQuoted += row.amountQuoted;
                    amountPaid += row.amountPaid;
                }
            });
        });
        let msg = 'Selected: ' + numSelected + ' item' + (numSelected == 1 ? '' : 's');
        msg += ', Quoted: ' + TextHelpers.formatCurrency(amountQuoted) + ', Paid: ' + TextHelpers.formatCurrency(amountPaid);
        return msg;
    }
}

export default Banking;