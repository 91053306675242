import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import SideNav      from 'components/layout/SideNav';
import TopNav       from 'components/layout/TopNav';

//-------------------------------------------------------------------------------------------------------------------

class Layout extends Component {

    render() {
        //const { loginInfo } = this.props;

        return (<>
            
            <SideNav {...this.props} />

            {/*
            {loginInfo.accounts.length > 1 &&
                <div className="current-account">
                    Current account: <strong>{loginInfo.account.accountName}</strong>
                </div>
            }
            */}

            <main>

                <div className="main-content">

                    {this.props.children}

                </div>

            </main>

        </>);
    }
}

export default withRouter(Layout);