import React from 'react';
import Creatable from 'react-select/creatable';

// Services & Helpers
import API from 'API';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

// Components
import Loader from 'components/common/Loader';
import EditChimneyNotesModal from 'components/customer/EditChimneyNotesModal';
import { CloseButton } from 'react-bootstrap';

//-----------------------------------------------------------------

class EditChimneys extends React.Component {

    constructor(props) {
        super(props);

        this.editChimneyNotesModalRef = React.createRef();

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    load() {
        return new Promise(async (resolve) => {
            const chimneyTypes = await API.call('chimney-type/list');
            this.setState({
                chimneyTypes,
                isLoading: false
            }, resolve);
        });
    }

    addChimneyType(chimneyType) {
        return new Promise(async (resolve) => {
            // Add on server
            chimneyType = { name: chimneyType.label };
            chimneyType.id = await API.call('chimney-type/save', chimneyType);

            // Add to local list
            const chimneyTypes = [...this.state.chimneyTypes];
            chimneyTypes.push(chimneyType);
            this.setState({
                chimneyTypes
            }, () => {
                resolve(chimneyType.id);
            });
        });
    }

    async editNotes(chimney) {
        const notes = await this.editChimneyNotesModalRef.current.open({
            notes: chimney.notes
        });
        this.props.updateFields(chimney, { notes });
    }

    confirmRemove(chimney) {
        const confirm = window.confirm('Are you sure you want to remove this chimney?');
        if (confirm) {
            this.props.updateFields(chimney, { isDeleted: true });
        }
    }

    startDedupe() {
        this.setState({
            isDeduping: true,
            selectedChimneyIDs: {},
            primaryChimneyID: null
        });
    }
    
    cancelDedupe() {
        this.setState({
            isDeduping: false
        });
    }

    toggleChimney(id) {
        const selectedChimneyIDs = { ...this.state.selectedChimneyIDs };
        let { primaryChimneyID } = this.state;
        if (!primaryChimneyID) primaryChimneyID = id;
        if (selectedChimneyIDs[id]) {
            delete selectedChimneyIDs[id];
            if (id == primaryChimneyID) {
                const otherIDs = Object.keys(selectedChimneyIDs);
                primaryChimneyID = (otherIDs.length > 0 ? otherIDs[0] : null);
            }
        }
        else selectedChimneyIDs[id] = true;
        this.setState({ selectedChimneyIDs, primaryChimneyID });
    }
    
    async confirmDedupe() {
        const confirm = window.confirm('Are you sure you want to de-duplicate these chimneys?');
        if (confirm) {
            this.setState({ isLoading: true });
            const { primaryChimneyID, selectedChimneyIDs } = this.state;
            await API.call('dedupe/run-chimneys', {
                primaryChimneyID,
                selectedChimneyIDs: Object.keys(selectedChimneyIDs)
            });
            this.cancelDedupe();
            this.props.onDedupe();
        }
    }
    
    //------------------------------------------------------------------------------------------------------

    render() {
        const { isLoading, chimneyTypes, isDeduping, selectedChimneyIDs } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        // Build chimney type options
        let chimneyTypeOptions = [
            { label: '(Unspecified)', value: '' }
        ];
        chimneyTypeOptions = chimneyTypeOptions.concat(chimneyTypes.map(ct => ({
            value: ct.id,
            label: (ct.code ? ct.code + ' - ' : '') + ct.name
        })));

        // Get list of chimneys with 'new' at the end
        const chimneys = [...this.props.chimneys.filter(c => !c.isDeleted)];
        if (!isDeduping) {
            chimneys.push({ isNew: true });
        }

        return (<>

            <h2 className="mt-4">
                Chimneys ({chimneys.length - 1})

                {!isDeduping ?
                    <button type="button" className="btn btn-secondary ms-auto" onClick={() => this.startDedupe()}>
                        <span className="fa-solid fa-copy" />{' '}
                        De-duplicate
                    </button> : <>
                        <button type="button" className="btn btn-secondary ms-auto" onClick={() => this.cancelDedupe()}>
                            <span className="fa-solid fa-times" />{' '}
                            Cancel
                        </button>
                        <button type="button" className="btn btn-secondary ms-3" onClick={() => this.confirmDedupe()} disabled={Object.keys(selectedChimneyIDs).length < 2}>
                            <span className="fa-solid fa-check" />{' '}
                            Confirm
                        </button>
                </>}
            </h2>

            <table className="cust-chimneys-table table table-bordered">
                <thead>
                    <tr>
                        {isDeduping &&
                            <th className="select-col"></th>
                        }
                        <th className="type-col">Type</th>
                        <th className="floor-col">Floor</th>
                        <th className="location-col">Location</th>
                        <th className="is-in-use-col">In use</th>
                        <th className="notes-col"></th>
                    </tr>
                </thead>
                <tbody>
                    {chimneys.map(chimney => {
                        // Set up select options
                        let floorOptions = [
                            { label: '(Unspecified)', value: '' }
                        ];
                        floorOptions = floorOptions.concat(ReferenceHelpers.Floors);
                        if (!floorOptions.find(f => chimney.floor == f.value)) {
                            floorOptions.push({ value: chimney.floor, label: chimney.floor });
                        }

                        let locationOptions = [
                            { label: '(Unspecified)', value: '' }
                        ];
                        locationOptions = locationOptions.concat(ReferenceHelpers.Locations);
                        if (!locationOptions.find(f => chimney.location == f.value)) {
                            locationOptions.push({ value: chimney.location, label: chimney.location });
                        }

                        return (
                            <tr key={chimney.guid || Math.random()}>
                                {isDeduping && !!chimney.id &&
                                    <td className="select-col">
                                        <input
                                            type="checkbox"
                                            checked={selectedChimneyIDs[chimney.id] || ''}
                                            onChange={e => this.toggleChimney(chimney.id)}
                                        />
                                    </td>
                                }
                                <td className="type-col">

                                    <Creatable
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        isClearable={false}
                                        options={chimneyTypeOptions}
                                        value={chimneyTypeOptions.find(ct => ct.value == chimney.chimneyTypeID)}
                                        formatCreateLabel={name => <>Add <b>{name}</b></>}
                                        isDisabled={isDeduping}
                                        onChange={async chimneyType => {
                                            if (chimneyType.__isNew__) {
                                                const id = await this.addChimneyType(chimneyType);
                                                this.props.updateFields(chimney, {
                                                    chimneyTypeID: id
                                                });
                                            } else {
                                                this.props.updateFields(chimney, {
                                                    chimneyTypeID: chimneyType.value
                                                });
                                            }
                                        }}
                                    />
                                </td>
                                <td className="floor-col">

                                    {!chimney.isNew &&
                                        <Creatable
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            isClearable={false}
                                            options={floorOptions}
                                            value={floorOptions.find(f => f.label == chimney.floor)}
                                            formatCreateLabel={name => <>Add <b>{name}</b></>}
                                            isDisabled={isDeduping}
                                            onChange={async floor => {
                                                this.props.updateFields(chimney, {
                                                    floor: floor.label
                                                });
                                            }}
                                        />
                                    }

                                </td>
                                <td className="location-col">

                                    {!chimney.isNew &&
                                        <Creatable
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            isClearable={false}
                                            options={locationOptions}
                                            value={locationOptions.find(f => f.label == chimney.location)}
                                            formatCreateLabel={name => <>Add <b>{name}</b></>}
                                            isDisabled={isDeduping}
                                            onChange={async location => {
                                                this.props.updateFields(chimney, {
                                                    location: location.label
                                                });
                                            }}
                                        />
                                    }

                                </td>

                                <td className="is-in-use-col">

                                    {!chimney.isNew &&
                                        <input
                                            type="checkbox"
                                            checked={chimney.isInUse || false}
                                            onChange={e => this.props.updateFields(chimney, { isInUse: e.target.checked })}
                                        />
                                    }

                                </td>

                                <td className="notes-col floating-controls">
                                    <div>

                                        {!chimney.isNew && <>

                                            <button type="button" className="btn btn-primary" title={chimney.notes} onClick={() => this.editNotes(chimney)}>
                                                <span className={`${chimney.notes ? 'fa-solid' : 'fa-regular'} fa-comment`} />
                                            </button>

                                            {!isDeduping &&
                                                <div className="btns">

                                                    <button type="button" className="btn btn-danger" onClick={() => this.confirmRemove(chimney)}>
                                                        <span className="fa fa-times" />
                                                    </button>

                                                </div>
                                            }

                                        </>}

                                    </div>
                                </td>

                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <EditChimneyNotesModal
                ref={this.editChimneyNotesModalRef}
            />

        </>);
    }

}

export default EditChimneys;
