import React from 'react';

// Services & Helpers
import API from 'API';
import FormHelper from 'helpers/FormHelper';
import TextHelpers from 'helpers/TextHelpers';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditAddress extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.form = new FormHelper({
            fields: {
                postcode: {
                    label: 'Postcode',
                    type: 'text'
                },
                line1: {
                    label: 'Address 1',
                    type: 'text',
                    props: {
                        id: (this.props.prefix || '') + 'address1',
                        onBlur: (e) => {
                            if (this.props.checkMissingHouseNumber && this.line1Changed) {
                                this.line1Changed = false;
                                this.props.checkMissingHouseNumber(e.target.value);
                            }
                        }
                    }
                },
                line2: {
                    label: 'Address 2',
                    type: 'text'
                },
                town: {
                    label: 'Town',
                    type: 'text'
                }
            },
            getValue: (fieldName) => this.props.address[fieldName],
            setValue: (fieldName, value) => {
                if (fieldName == 'line1') {
                    this.line1Changed = this;
                }
                this.updateField(fieldName, value);
            }
        });
    }

    updateField(fieldName, value) {
        this.props.updateFields({ [fieldName]: value });

        // Trigger postcode lookup
        if (fieldName == 'postcode') {
            const newPostcode = TextHelpers.normalisePostcode(value);
            if (TextHelpers.isValidUKPostcode(newPostcode)) {
                const oldPostcode = TextHelpers.normalisePostcode(this.props.address.postcode);
                if (newPostcode != oldPostcode) {
                    this.debounceLookup(newPostcode);
                }
            }
        }
    }

    async debounceLookup(postcode) {
        clearTimeout(this.lookupTimeout);
        this.lookupTimeout = setTimeout(() => {
            this.lookup(postcode);
        }, 200);
    }

    async lookup(postcode) {
        this.setState({ isLookingUp: true });
        const address = await API.call('property/lookup-address/' + postcode);

        // Check that the postcode falls into this territory
        let checkTerritoryResult;
        if (this.props.checkTerritory) {
            checkTerritoryResult = await API.call('property/check-territory/' + postcode);
        }

        if (address.isSuccess) {
            this.props.updateFields({
                line1: ` ${address.line1 || ''}`,
                line2: address.line2 || '',
                town: address.town || '',
                postcode: address.postcode || ''
            }, () => {
                this.line1Changed = true;

                // Focus on address1 and move the caret to the beginning
                const address1El = document.querySelector(`#${this.props.prefix || ''}address1`);
                if (address1El) {
                    address1El.focus();
                    address1El.setSelectionRange(0, 0);
                }
            });
        }
        this.setState({
            isLookingUp: false,
            checkTerritoryResult
        });
    }

    async confirmSwitch(checkTerritoryResult) {
        const confirm = window.confirm(`Move this customer to ${checkTerritoryResult.accountName}?`);
        if (confirm) {
            this.props.onSwitch(checkTerritoryResult.accountID);
        }
    }

    render() {
        const { isLookingUp, checkTerritoryResult } = this.state;
        let haveAccessToOtherTerritory = false;
        if (checkTerritoryResult && this.props.loginInfo) {
            haveAccessToOtherTerritory = this.props.loginInfo.accounts.some(a => a.accountID == checkTerritoryResult.accountID);
        }

        return (<>

            <div className="row">

                <div className="col-md-3" style={{ position: 'relative' }}>

                    {this.form.render('postcode')}

                </div>

                <div className="col-md-3">

                    {this.form.render('line1')}

                </div>

                <div className="col-md-3">

                    {this.form.render('line2')}

                </div>

                <div className="col-md-3">

                    {this.form.render('town')}

                </div>
            </div>

            {!isLookingUp && checkTerritoryResult && checkTerritoryResult.isOutOfTerritory &&
                <div className={`check-territory-result alert ${checkTerritoryResult.accountName ? 'alert-danger' : 'alert-warning'}`}>
                    <span className="fa-solid fa-triangle-exclamation" title="Out of territory" />{' '}
                    {checkTerritoryResult.accountName ?
                        <>
                            This postcode is in the <b>{checkTerritoryResult.accountName}</b> territory.
                            {haveAccessToOtherTerritory ?
                                <button className="btn btn-secondary switch-customer-account-button" onClick={() => this.confirmSwitch(checkTerritoryResult)}>
                                    <span className="fa fa-repeat" />{' '}
                                    Switch
                                </button> :
                                <>Please check with them first.</>
                            }
                        </> :
                        <>This postcode is out of your territory, but is unclaimed by another territory</>
                    }
                </div>
            }

        </>);
    }

}

export default EditAddress;
