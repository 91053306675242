import TextHelpers from 'helpers/TextHelpers';

export default class Print {

	static url(url, param) {
		return new Promise(function (resolve, reject) {
			//Print.printModal = bootbox.dialog({
			//	message:
			//		'<div class="text-info text-center">' +
			//		'	Please wait...<br/></br>' +
			//		'	<input type="button" value="Cancel" class="btn btn-warning" onclick="App.cancelFileDownload();return false" />' +
			//		'</div>'
			//});
			
			// Add querystring
			if (!param) {
				param = {};
			}
			param.random = Math.random(); // Add unique number to URL to ensure it always loads fresh
			url = TextHelpers.buildURL(url, param);

			// Create the printing IFrame
			if (!Print.printIFrame) {
				var iFrame = document.createElement('IFRAME');
				iFrame.style.position = 'absolute';
				iFrame.style.left = '0px';
				iFrame.style.top = '0px';
				iFrame.style.width = '1px';
				iFrame.style.height = '1px';
				iFrame.style.opacity = '0';

				//// DEBUG
				//iFrame.style.width = '500px';
				//iFrame.style.height = '500px';
				//iFrame.style.opacity = '1';
				//iFrame.style.border = '2px solid black';
				//iFrame.style.zIndex = '99999999';
				////alert(url);
				//// DEBUG

				document.body.appendChild(iFrame);
				Print.printIFrame = iFrame;
			}

			// Call print on load
			var fnOnLoad = function () {
				if (Print.printIFrame.src) {
					window.setTimeout(() => {
						Print.printIFrame.focus();
						Print.printIFrame.contentWindow.print();
					}, 250);
					window.setTimeout(() => {
						resolve();
					}, 500);
				}
			};
			Print.printIFrame.removeEventListener('load', fnOnLoad);

			if (param.isInline) {
				Print.printIFrame.addEventListener('load', fnOnLoad);
			} else {
				window.setTimeout(() => {
					resolve();
				}, 500);
			}

			// Point to the print URL
			Print.printIFrame.src = url;
		});
    }
}
