import React from 'react';
import Modal from 'react-bootstrap/Modal';
import API from 'API';

import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class SelectAccountModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }
    
    select(accountCode) {
        window.location = `/${accountCode}`;
        this.close();
    }

    openAll() {
        const { loginInfo } = this.props;
        loginInfo.accounts.forEach(a => {
            if (a.accountID != loginInfo.accountID) {
                window.open(`/${a.accountCode}`, a.accountCode);
            }
        });
    }

    render() {
        const {
            isOpen
        } = this.state;

        if (!isOpen) {
            return null;
        }

        const buttons = (<>
            <button type="button" className="btn btn-tertiary me-auto" onClick={() => this.openAll()}>
                Open all in tabs
            </button>
            <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                Cancel
            </button>
        </>);

        return (
            <Modal show onHide={() => this.close()} className="select-account-modal" backdrop="static" keyboard={false}>
                <form onSubmit={e => { e.stopPropagation(); e.preventDefault(); this.saveAndClose() }}>
                    <Modal.Header>
                        {buttons}
                    </Modal.Header>
                    <Modal.Body>

                        {this.renderInner()}

                    </Modal.Body>
                    <Modal.Footer>
                        {buttons}
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }

    renderInner() {
        const {
            loginInfo
        } = this.props;

        return (
            <div>
                {loginInfo.accounts.filter(a => a.accountID != loginInfo.accountID).map(account =>
                    <React.Fragment key={account.accountID}>
                        <h2 className="mb-1">{account.accountName}</h2>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <a href={`/${account.accountCode}`} className="btn btn-tertiary w-100">
                                    <span className="fa-solid fa-arrow-down" />{' '}
                                    Open in this tab
                                </a>
                            </div>
                            <div className="col-md-6">
                                <a href={`/${account.accountCode}`} className="btn btn-tertiary w-100" target="_blank">
                                    <span className="fa-solid fa-arrow-right" />{' '}
                                    Open in new tab
                                </a>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default SelectAccountModal;